import { IRight } from '../../@types/model/auth/right/right';
import { IRightUpsert } from '../../@types/model/auth/right/rightUpsert';
import { IUserUpsert } from '../../@types/model/auth/user/userUpsert';
import { IRole } from '../../@types/model/auth/role/role';
import { IRoleUpsert } from '../../@types/model/auth/role/roleUpsert';
import ArrayHelper from '../../service/helper/arrayHelper';
import RightHttpService from '../../service/http/right/rightHttpService';
import RoleHttpService from '../../service/http/right/roleHttpService';
import UserHttpService from '../../service/http/right/userHttpService';
import RightActions from './actions';
import lodash from 'lodash';
import AuthActions from '../auth/actions';
import * as localStorageService from '../../service/localStorageService';
import GeneralThunk from '../general/thunk';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkApi } from '../../@types/redux';
import { IUser } from '../../@types/model/auth/user/user';
import { IUserToken } from '../../@types/model/auth/userToken/userToken';

export default class RightThunks {
    /**
     * Retrieves the list of users from the API, updating the redux state once complete. Performs no
     * action if no users have been retrieved.
     *
     * @returns {Array<IUser> | null}
     */
    public static getUserList = createAsyncThunk<
    Array<IUser> | null,
    {
        ignoreRights ?: boolean | null;
        isActive ?: boolean | null;
    } | undefined,
    ThunkApi>(
        'RIGHTS_LOAD_USERS',
        async (params, thunkApi) => {
            try {
                thunkApi.dispatch(RightActions.setIsLoading(true));

                const res = await UserHttpService.userGetList(params?.ignoreRights ?? null, params?.isActive ?? null);
                thunkApi.dispatch(RightActions.setUserData(res.data));

                return res.data;
            } catch (e) {
                /**
                 * Only show error if there is actually an error.
                 * Cancelation errors are empty.
                 */
                if (e) {
                    thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while loading users.', e }));
                }
                return null;
            } finally {
                thunkApi.dispatch(RightActions.setIsLoading(false));
            }
        },
    );

    /**
     * Retrieves current users from the API, updating the redux state once complete. Performs no
     * action if user have been retrieved.
     *
     * @returns {IUser | null}
     */
    public static getCurrentUser = createAsyncThunk<
    IUser | null,
    undefined,
    ThunkApi>(
        'RIGHTS_LOAD_CURRENT_USER',
        async (params, thunkApi) => {
            try {
                thunkApi.dispatch(RightActions.setIsLoading(true));

                const res = await UserHttpService.getUser();

                const users = thunkApi.getState().right.userData;
                const newList = ArrayHelper.upsertElement(users, res.data, a => a.id === res.data.id);
                thunkApi.dispatch(RightActions.setUserData(newList ?? users));

                return res.data;
            } catch (e) {
                /**
                 * Only show error if there is actually an error.
                 * Cancelation errors are empty.
                 */
                if (e) {
                    thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while loading current user.', e }));
                }
                return null;
            } finally {
                thunkApi.dispatch(RightActions.setIsLoading(false));
            }
        },
    );

    /**
     * Retrieves the list of rights from the API, updating the redux state once complete. Performs no
     * action if no rights have been retrieved.
     *
     * @returns {Array<IRight> | null}
     */
    public static getRightList = createAsyncThunk<
    Array<IRight> | null,
    undefined,
    ThunkApi>(
        'RIGHTS_LOAD_RIGHTS',
        async (params, thunkApi) => {
            try {
                thunkApi.dispatch(RightActions.setIsLoading(true));

                const res = await RightHttpService.rightGetList();
                thunkApi.dispatch(RightActions.setRightData(res.data));

                return res.data;
            } catch (e) {
                /**
                 * Only show error if there is actually an error.
                 * Cancelation errors are empty.
                 */
                if (e) {
                    thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while loading rights.', e }));
                }
                return null;
            } finally {
                thunkApi.dispatch(RightActions.setIsLoading(false));
            }
        },
    );

    /**
     * Retrieves the list of roles from the API, updating the redux state once complete. Performs no
     * action if no roles have been retrieved.
     *
     * @returns {Array<IRole> | null}
     */
    public static getRoleList = createAsyncThunk<
    Array<IRole> | null,
    undefined,
    ThunkApi>(
        'RIGHTS_LOAD_ROLES',
        async (params, thunkApi) => {
            try {
                thunkApi.dispatch(RightActions.setIsLoading(true));

                const res = await RoleHttpService.roleGetList();
                thunkApi.dispatch(RightActions.setRoleData(res.data));

                return res.data;
            } catch (e) {
                /**
                 * Only show error if there is actually an error.
                 * Cancelation errors are empty.
                 */
                if (e) {
                    thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while loading roles.', e }));
                }
                return null;
            } finally {
                thunkApi.dispatch(RightActions.setIsLoading(false));
            }
        },
    );

    /****************************** UPSERT *********************************/

    /**
     * Updates user employee number.
     *
     * @param employeeNumber
     * @returns {IUserToken | null}
     */
    public static setEmployeeNumber = createAsyncThunk<
    IUserToken | null,
    string,
    ThunkApi>(
        'RIGHTS_SET_EMPLOYEE_NUMBER',
        async (employeeNumber, thunkApi) => {
            try {
                thunkApi.dispatch(RightActions.setIsLoading(true));

                const res = await UserHttpService.setEmployeeNumber(employeeNumber);

                await localStorageService.setLocalStorageSession(res.data);
                thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Employee number has been set.'));

                return res.data;
            } catch (e) {
                thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'Invalid employee number.', e }));
                return null;
            } finally {
                thunkApi.dispatch(RightActions.setIsLoading(false));
            }
        },
    );

    /**
     * Updates user password by making request to api
     *
     * @param password
     * @returns {IUserToken | null}
     */
    public static setUserPassword = createAsyncThunk<
    IUserToken | null,
    string,
    ThunkApi>(
        'RIGHTS_SET_PASSWORD',
        async (password, thunkApi) => {
            try {
                thunkApi.dispatch(RightActions.setIsLoading(true));

                const res = await UserHttpService.setPassword(password);

                await localStorageService.setLocalStorageSession(res.data);
                thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Password has been set successfully.'));
                return null;
            } catch (e) {
                thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while saving password.', e }));
                return null;
            } finally {
                thunkApi.dispatch(RightActions.setIsLoading(false));
            }
        },
    );

    /**
     * Inserts a user, calling the API. Once complete, the redux
     * state is updated to reflect the change.
     *
     * @param {Array<IUserUpsert>} data
     * @returns {Array<IUser>}
     */
    public static upsertUser = createAsyncThunk<
    Array<IUser> | null,
    {
        upsert : Array<IUserUpsert>;
        type : 'Add' | 'Edit';
    },
    ThunkApi>(
        'RIGHTS_UPSERT_USERS',
        async (params, thunkApi) => {
            try {
                const state = thunkApi.getState();
                const session = state.auth.session;
                const userList = lodash.map(state.right.userData, x => x);

                thunkApi.dispatch(RightActions.setIsLoading(true));

                // insert entry
                const res = await UserHttpService.userUpsert(params.upsert, params.type);

                if (res.data.length == 1 && !!session && session.user.id === res.data[0].id) {
                    const updatedSession : IUserToken = {
                        ...session,
                        user: res.data[0],
                    };
                    thunkApi.dispatch(AuthActions.setSession(updatedSession));
                    await localStorageService.setLocalStorageSession(updatedSession);
                }

                const newList = ArrayHelper.uniqueJoin(res.data, userList, a => a.id);
                thunkApi.dispatch(RightActions.setUserData(newList));
                thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry saved successfully.'));

                return res.data;
            } catch (e) {
                thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while saving the user.', e }));
                return null;
            } finally {
                thunkApi.dispatch(RightActions.setIsLoading(false));
            }
        },
    );

    /**
     * Inserts a right, calling the API. Once complete, the redux
     * state is updated to reflect the change.
     *
     * @param {IRightUpsert} data
     * @returns {IRight | null}
     */
    public static upsertRight = createAsyncThunk<
    IRight | null,
    {
        upsert : IRightUpsert;
        type : 'Add' | 'Edit';
    },
    ThunkApi>(
        'RIGHTS_UPSERT_RIGHT',
        async (params, thunkApi) => {
            try {
                const state = thunkApi.getState();
                const rightList = lodash.map(state.right.rightData, x => x);

                thunkApi.dispatch(RightActions.setIsLoading(true));

                // insert entry
                const res = await RightHttpService.rightUpsert(params.upsert, params.type);

                const newList = ArrayHelper.upsertElement(rightList, res.data, a => a.id === res.data.id);
                thunkApi.dispatch(RightActions.setRightData(newList ?? []));
                thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry saved successfully.'));

                return res.data;
            } catch (e) {
                thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while saving the right.', e }));
                return null;
            } finally {
                thunkApi.dispatch(RightActions.setIsLoading(false));
            }
        },
    );

    /**
     * Inserts a role, calling the API. Once complete, the redux
     * state is updated to reflect the change.
     *
     * @param {IRoleUpsert} data
     * @returns {IRole | null}
     */
    public static upsertRole = createAsyncThunk<
    IRole | null,
    {
        upsert : IRoleUpsert;
        type : 'Add' | 'Edit';
    },
    ThunkApi>(
        'RIGHTS_UPSERT_ROLE',
        async (params, thunkApi) => {
            try {
                const state = thunkApi.getState();
                const roleList = lodash.map(state.right.roleData, x => x);

                thunkApi.dispatch(RightActions.setIsLoading(true));

                // insert entry
                const res = await RoleHttpService.roleUpsert(params.upsert, params.type);

                const newList = ArrayHelper.upsertElement(roleList, res.data, a => a.id === res.data.id);
                thunkApi.dispatch(RightActions.setRoleData(newList ?? []));
                thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry saved successfully.'));

                return res.data;
            } catch (e) {
                thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while saving the role.', e }));
                return null;
            } finally {
                thunkApi.dispatch(RightActions.setIsLoading(false));
            }
        },
    );

    /****************************** DELETE *********************************/

    /**
     * Deletes a user, calling the API. Once complete, the change is reflected in the redux state.
     *
     * @param {number} userId
     * @returns {boolean}
     */
    public static deleteUser = createAsyncThunk<
    boolean,
    number,
    ThunkApi>(
        'RIGHTS_DELETE_USER',
        async (userId, thunkApi) => {
            try {
                thunkApi.dispatch(RightActions.setIsLoading(true));

                await UserHttpService.userDelete(userId);

                const users : Array<IUser> = thunkApi.getState().right.userData;
                const deletedUser = users.find(x => x.id === userId);
                const index = users.findIndex(x => x.id === userId);

                if (deletedUser && index >= 0) {
                    const updatedUser : IUser = {
                        ...deletedUser,
                        isActive: false,
                    };
                    const newList = ArrayHelper.setElement(users, index, updatedUser);
                    thunkApi.dispatch(RightActions.setUserData(newList));
                }

                thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry deleted successfully.'));

                return true;
            } catch (e) {
                thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while deleting user.', e }));
                return false;
            } finally {
                thunkApi.dispatch(RightActions.setIsLoading(false));
            }
        },
    );

    /**
     * Deletes a right, calling the API. Once complete, the change is reflected in the redux state.
     *
     * @param {number} rightId
     * @returns {boolean}
     */
    public static deleteRight = createAsyncThunk<
    boolean,
    number,
    ThunkApi>(
        'RIGHTS_DELETE_RIGHT',
        async (rightId, thunkApi) => {
            try {
                thunkApi.dispatch(RightActions.setIsLoading(true));

                await RightHttpService.rightDelete(rightId);

                const rights : Array<IRight> = thunkApi.getState().right.rightData;
                const deletedRight = rights.find(x => x.id === rightId);
                const index = rights.findIndex(x => x.id === rightId);

                if (deletedRight && index >= 0) {
                    const updatedRight : IRight = {
                        ...deletedRight,
                        isActive: false,
                    };
                    const newList = ArrayHelper.setElement(rights, index, updatedRight);
                    thunkApi.dispatch(RightActions.setRightData(newList));
                }

                thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry deleted successfully.'));

                return true;
            } catch (e) {
                thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while deleting right.', e }));
                return false;
            } finally {
                thunkApi.dispatch(RightActions.setIsLoading(false));
            }
        },
    );

    /**
     * Deletes a role, calling the API. Once complete, the change is reflected in the redux state.
     *
     * @param {number} roleId
     * @returns {boolean}
     */
    public static deleteRole = createAsyncThunk<
    boolean,
    number,
    ThunkApi>(
        'RIGHTS_DELETE_ROLE',
        async (roleId, thunkApi) => {
            try {
                thunkApi.dispatch(RightActions.setIsLoading(true));

                await RoleHttpService.roleDelete(roleId);

                const roles : Array<IRole> = thunkApi.getState().right.roleData;
                const deletedRole = roles.find(x => x.id === roleId);
                const index = roles.findIndex(x => x.id === roleId);

                if (deletedRole && index >= 0) {
                    const updatedRole : IRole = {
                        ...deletedRole,
                        isActive: false,
                    };
                    const newList = ArrayHelper.setElement(roles, index, updatedRole);
                    thunkApi.dispatch(RightActions.setRoleData(newList));
                }

                thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry deleted successfully.'));

                return true;
            } catch (e) {
                thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while deleting role.', e }));
                return false;
            } finally {
                thunkApi.dispatch(RightActions.setIsLoading(false));
            }
        },
    );
}
