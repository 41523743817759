import { createReducer } from '@reduxjs/toolkit';
import SyncActions from './actions';

export interface ISyncState {
    isLoading : boolean;
    syncStatus : boolean;
}

const initialState = {
    isLoading: false,
    syncStatus: false,
};

const syncReducer = createReducer<ISyncState>(initialState, (builder) => {
    builder.addCase(SyncActions.setIsLoading, (state, action) => {
        state.isLoading = action.payload;
    });
    builder.addCase(SyncActions.setSyncStatus, (state, action) => {
        state.syncStatus = action.payload;
    });
});

export default syncReducer;
