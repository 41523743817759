import { createReducer } from '@reduxjs/toolkit';
import { IBatchAudit } from '../../@types/model/batch/batchAudit';
import { IBatch } from '../../@types/model/batch/batch';
import BatchTransactionActions from './actions';

export interface IBatchTransactionState {
    isLoading : boolean;
    batchTransactionList : Array<IBatch> | null;
    batchTransactionAuditLogs : Array<IBatchAudit> | null;
}

const initialState = {
    isLoading: false,
    batchTransactionList: null,
    batchTransactionAuditLogs: null,
};

const batchReducer = createReducer<IBatchTransactionState>(initialState, (builder) => {
    builder.addCase(BatchTransactionActions.setIsLoading, (state, action) => {
        state.isLoading = action.payload;
    });
    builder.addCase(BatchTransactionActions.setBatchTransactionData, (state, action) => {
        state.batchTransactionList = action.payload;
    });
    builder.addCase(BatchTransactionActions.setBatchTransactionAuditData, (state, action) => {
        state.batchTransactionAuditLogs = action.payload;
    });
    builder.addCase(BatchTransactionActions.reset, () => {
        return initialState;
    });
});

export default batchReducer;
