import axios, { AxiosPromise, Canceler, CancelTokenStatic } from 'axios';
import { IEmployee } from '../../../@types/model/masterData/employee/employee';
import { IEmployeeUpsert } from '../../../@types/model/masterData/employee/employeeUpsert';

export default class EmployeeHttpService {
    private static readonly CancelToken : CancelTokenStatic = axios.CancelToken;
    /**
     * Allows you to cancel list request
     */
    public static cancelGetList ?: Canceler;

    public static masterDataEmployeeGetList() : AxiosPromise<Array<IEmployee>> {
        if (this.cancelGetList) {
            this.cancelGetList('GET Canceled');
            this.cancelGetList = undefined;
        }

        return axios.get(`${API_URL}/v1/MasterData/Employee/GetList`, {
            cancelToken: new this.CancelToken((c) => {
                this.cancelGetList = c;
            }),
        });
    }

    public static masterDataEmployeeGetListByDivisionIds(divisionIds : Array<number> | null) : AxiosPromise<Array<IEmployee>> {
        if (this.cancelGetList) {
            this.cancelGetList('GET Canceled');
            this.cancelGetList = undefined;
        }

        return axios.get(`${API_URL}/v1/MasterData/Employee/GetList`, {
            params: {
                divisionIds,
            },
            paramsSerializer: {
                indexes: null
            },
            cancelToken: new this.CancelToken((c) => {
                this.cancelGetList = c;
            }),
        });
    }

    public static employeeUpsert = (employeeUpsert : IEmployeeUpsert, type : 'Add' | 'Edit') : AxiosPromise<IEmployee> => {
        return axios.post(`${API_URL}/v1/MasterData/Employee/${type}`, employeeUpsert);
    }

    public static employeeDelete = (id : number) : AxiosPromise<IEmployee> => {
        return axios.delete(`${API_URL}/v1/MasterData/Employee/Delete`, {
            params: { id },
        });
    }
}