import axios, { AxiosPromise, Canceler, CancelTokenStatic } from 'axios';
import { IDivision } from '../../../@types/model/masterData/division/division';
import { IDivisionUpsert } from '../../../@types/model/masterData/division/divisionUpsert';

export default class DivisionHttpService {
    private static readonly CancelToken : CancelTokenStatic = axios.CancelToken;
    /**
     * Allows you to cancel list request
     */
    public static cancelGetList ?: Canceler;

    public static masterDataDivisionGetList(divisionIds : Array<number> | null) : AxiosPromise<Array<IDivision>> {
        if (this.cancelGetList) {
            this.cancelGetList('GET Canceled');
            this.cancelGetList = undefined;
        }

        return axios.get(`${API_URL}/v1/MasterData/Division/GetList`, {
            params: {
                divisionIds,
            },
            paramsSerializer: {
                indexes: null
            },
            cancelToken: new this.CancelToken((c) => {
                this.cancelGetList = c;
            }),
        });
    }

    public static divisionUpsert = (divisionUpsert : IDivisionUpsert, type : 'Add' | 'Edit') : AxiosPromise<IDivision> => {
        return axios.post(`${API_URL}/v1/MasterData/Division/${type}`, divisionUpsert);
    }

    public static divisionDelete = (divisionId : number) : AxiosPromise<IDivision> => {
        return axios.delete(`${API_URL}/v1/MasterData/Division/Delete`, {
            params: { divisionId },
        });
    }
}