import axios, { AxiosPromise, Canceler, CancelTokenStatic } from 'axios';
import { IRight } from '../../../@types/model/auth/right/right';
import { IRightUpsert } from '../../../@types/model/auth/right/rightUpsert';

export default class RightHttpService {
    private static readonly CancelToken : CancelTokenStatic = axios.CancelToken;
    /**
     * Allows you to cancel list request
     */
    public static cancelGetList ?: Canceler;

    public static rightGetList() : AxiosPromise<Array<IRight>> {
        if (this.cancelGetList) {
            this.cancelGetList();
            this.cancelGetList = undefined;
        }

        return axios.get(`${API_URL}/v1/Right/Right/GetList`, {
            cancelToken: new this.CancelToken((c) => {
                this.cancelGetList = c;
            }),
        });
    }

    public static rightUpsert = (rightUpsert : IRightUpsert, type : 'Add' | 'Edit') : AxiosPromise<IRight> => {
        return axios.post(`${API_URL}/v1/Right/Right/${type}`, rightUpsert);
    }

    public static rightDelete = (rightId : number) : AxiosPromise<IRight> => {
        return axios.delete(`${API_URL}/v1/Right/Right/Delete`, {
            params: { rightId },
        });
    }
}
