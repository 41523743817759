import { createReducer } from '@reduxjs/toolkit';
import FreshServiceActions from './actions';

export interface IFreshServiceState {
    isLoading : boolean;
    isSupportTicketDialogOpen : boolean;
    supportTicketTitle : string;
    supportTicketDescription : string;
}

const initialState = {
    isLoading: false,
    isSupportTicketDialogOpen: false,
    supportTicketTitle: '',
    supportTicketDescription: '',
};

const freshServiceReducer = createReducer<IFreshServiceState>(initialState, (builder) => {
    builder.addCase(FreshServiceActions.setIsLoading, (state, action) => {
        state.isLoading = action.payload;
    });
    builder.addCase(FreshServiceActions.setIsSupportTicketDialogOpen, (state, action) => {
        state.isSupportTicketDialogOpen = action.payload;
    });
    builder.addCase(FreshServiceActions.setSupportTicketTitle, (state, action) => {
        state.supportTicketTitle = action.payload;
    });
    builder.addCase(FreshServiceActions.setSupportTicketDescription, (state, action) => {
        state.supportTicketDescription = action.payload;
    });
    builder.addCase(FreshServiceActions.reset, () => {
        return initialState;
    });
});

export default freshServiceReducer;
