import axios, { AxiosPromise, Canceler, CancelTokenStatic } from 'axios';
import { ICompanyReference } from '../../../@types/model/masterData/companyReference/companyReference';
import { ICompanyReferenceUpsert } from '../../../@types/model/masterData/companyReference/companyReferenceUpsert';

export default class CompanyReferenceHttpService {
    private static readonly CancelToken : CancelTokenStatic = axios.CancelToken;
    /**
     * Allows you to cancel list request
     */
    public static cancelGetList ?: Canceler;

    public static getList(divisionIds : Array<number>) : AxiosPromise<Array<ICompanyReference>> {
        if (this.cancelGetList) {
            this.cancelGetList('GET Canceled');
            this.cancelGetList = undefined;
        }

        return axios.get(`${API_URL}/v1/MasterData/CompanyReference/GetList`, {
            params: {
                divisionIds,
            },
            paramsSerializer: {
                indexes: null
            },
            cancelToken: new this.CancelToken((c) => {
                this.cancelGetList = c;
            }),
        });
    }

    public static upsert = (upsert : ICompanyReferenceUpsert, type : 'Add' | 'Edit') : AxiosPromise<ICompanyReference> => {
        return axios.post(`${API_URL}/v1/MasterData/CompanyReference/${type}`, upsert);
    }

    public static delete = (id : number) : AxiosPromise<ICompanyReference> => {
        return axios.delete(`${API_URL}/v1/MasterData/CompanyReference/Delete`, {
            params: { id },
        });
    }
}