import * as Yup from 'yup';
import { YupSchema } from '../../yupHelper';
import { IFreshServiceTicketFormValues } from './freshServiceTicketFormValues';
import { IFreshServiceTicketUpsert } from './freshServiceTicketUpsert';
import { FreshServicePriority, FreshServiceStatus } from '../../../appConstants';
import { IUser } from '../auth/user/user';

type YupShape = Record<keyof IFreshServiceTicketFormValues, YupSchema>

export default class FreshServiceTicketModelHelper {
    public static createFormValues = (
        systemName : string,
        systemVersion : string,
        location : string,
        name : string,
        email : string,
        description : string
    ) : IFreshServiceTicketFormValues => {
        return {
            systemName: systemName,
            systemVersion: systemVersion,
            location: location,
            name: name,
            email: email,
            description: description,
            status: FreshServiceStatus.Open,
            priority: FreshServicePriority.Low,
            referencedUser: null,
        };
    };

    public static createUpsert = (formValues : IFreshServiceTicketFormValues, users ?: Array<IUser>) : IFreshServiceTicketUpsert => {
        const referencedUser = users?.find(x => x.id === formValues.referencedUser?.value);
        let newDescription : string = formValues.description;

        if (referencedUser) {  
            // description string needs to be in html for fresh service to format ticket correctly
            newDescription += `<br> <br>
              Manager Name: ${referencedUser.name}<br>
              Employee Number: ${referencedUser.employeeNumber}<br>
              Email: ${referencedUser.email}<br>`;
        }

        return {
            systemName: formValues.systemName,
            systemVersion: formValues.systemVersion,
            location: formValues.location,
            name: formValues.name,
            email: formValues.email,
            description: newDescription,
            priority: formValues.priority,
            status: formValues.status
        };
    };

    // Validation schema used by formik. https://github.com/jquense/yup
    public static formSchema = () : Yup.ObjectSchema<YupShape> => Yup.object<YupShape>({
        systemName: Yup.string().required('Required'),
        systemVersion: Yup.string().required('Required'),
        location: Yup.string().required('Required'),
        name: Yup.string().required('Required'),
        email: Yup.string().email('Invalid email format').required('Required'),
        description: Yup.string().required('Required'),
        status: Yup.number().required('Required'),
        priority: Yup.number().required('Required'),
        referencedUser: Yup.object().nullable().required('Please select the person that you report to.'),
    });
}
