import { createAction } from '@reduxjs/toolkit';
import { withPayloadType } from '../../service/helper/reduxHelper';
import { IPaymentTransaction } from '../../@types/model/paymentTransactions/paymentTransaction';
import { IPaymentTransactionAudit } from '../../@types/model/paymentTransactions/paymentTransactionAudit';

export default class PaymentTransactionActions {
    public static setIsLoading = createAction('PAYMENT_TRANSACTION_SET_LOADING', withPayloadType<boolean>());
    public static setPaymentTransactionData = createAction('PAYMENT_TRANSACTION_SET_LIST', withPayloadType<Array<IPaymentTransaction> | null>());
    public static setPaymentTransactionAuditData = createAction('PAYMENT_TRANSACTION_AUDIT_LOG_SET_LIST', withPayloadType<Array<IPaymentTransactionAudit> | null>());
    public static reset = createAction('PAYMENT_TRANSACTION_RESET');
}
