import axios, { AxiosPromise, Canceler, CancelTokenStatic } from 'axios';
import { IField } from '../../../@types/model/masterData/field/field';
import { IFieldUpsert } from '../../../@types/model/masterData/field/fieldUpsert';

export default class FieldHttpService {
    private static readonly CancelToken : CancelTokenStatic = axios.CancelToken;
    /**
     * Allows you to cancel list request
     */
    public static cancelGetList ?: Canceler;
    public static cancelGetListByDepartment ?: Canceler;
    public static cancelGetListByProductionUnit ?: Canceler;

    public static masterDataFieldGetList(departmentIds ?: Array<number>, productionUnitIds ?: Array<number>) : AxiosPromise<Array<IField>> {
        if (this.cancelGetList) {
            this.cancelGetList('GET Canceled');
            this.cancelGetList = undefined;
        }

        return axios.get(`${API_URL}/v1/MasterData/Field/GetList`, {
            params: {
                departmentIds,
                productionUnitIds,
            },
            paramsSerializer: {
                indexes: null
            },
            cancelToken: new this.CancelToken((c) => {
                this.cancelGetList = c;
            }),
        });
    }

    public static fieldUpsert = (fieldUpsert : IFieldUpsert, type : 'Add' | 'Edit') : AxiosPromise<IField> => {
        return axios.post(`${API_URL}/v1/MasterData/Field/${type}`, fieldUpsert);
    }

    public static fieldDelete = (fieldId : number) : AxiosPromise<IField> => {
        return axios.delete(`${API_URL}/v1/MasterData/Field/Delete`, {
            params: { fieldId },
        });
    }
}