import axios, { AxiosPromise, Canceler, CancelTokenStatic } from 'axios';
import { IEmployeeSetup } from '../../../@types/model/masterData/employeeSetup/employeeSetup';
import { IEmployeeSetupUpsert } from '../../../@types/model/masterData/employeeSetup/employeeSetupUpsert';

export default class EmployeeSetupHttpService {
    private static readonly CancelToken : CancelTokenStatic = axios.CancelToken;
    /**
     * Allows you to cancel list request
     */
    public static cancelGetList ?: Canceler;

    public static masterDataEmployeeSetupGetList() : AxiosPromise<Array<IEmployeeSetup>> {
        if (this.cancelGetList) {
            this.cancelGetList('GET Canceled');
            this.cancelGetList = undefined;
        }

        return axios.get(`${API_URL}/v1/MasterData/EmployeeSetup/GetList`, {
            cancelToken: new this.CancelToken((c) => {
                this.cancelGetList = c;
            }),
        });
    }

    public static employeeSetupUpsert = (employeeSetupUpsert : IEmployeeSetupUpsert, type : 'Add' | 'Edit') : AxiosPromise<IEmployeeSetup> => {
        return axios.post(`${API_URL}/v1/MasterData/EmployeeSetup/${type}`, employeeSetupUpsert);
    }

    public static employeeSetupDelete = (id : number) : AxiosPromise<IEmployeeSetup> => {
        return axios.delete(`${API_URL}/v1/MasterData/EmployeeSetup/Delete`, {
            params: { id },
        });
    }
}