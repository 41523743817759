import { Transaction, UpgradeDB, openDb } from 'idb';
import { upgradeDb } from './localStorageService';
import { IPieceWorkTransactionAggregate } from '../@types/model/pieceWorkTransaction/pieceWorkTransactionAggregate';
import { IRawClockEmployeeGroupedAggregate } from '../@types/model/rawClock/rawClockTransaction/rawClockEmployeeGroupedAggregate';
import { IRawClockTransactionAggregate } from '../@types/model/rawClock/rawClockTransaction/rawClockTransactionAggregate';
import { IRawClockTransactionAggregateIndexedDBView } from '../@types/model/rawClock/rawClockTransaction/rawClockTransactionAggregateIndexedDBView';
import moment from 'moment';
import { IRawClockEmployeeGroupedAggregateIndexedDBView } from '../@types/model/rawClock/rawClockTransaction/rawClockEmployeeGroupedAggregateIndexedDBView';
import { IPieceWorkTransactionAggregateIndexedDBView } from '../@types/model/pieceWorkTransaction/pieceWorkTransactionAggregateIndexedDBView';
import { IWizardSummaryBarState } from '../@types/model/wizard/wizardSummaryBarState';
import { IRawClockSelectedEmployeeSetup } from '../@types/model/rawClock/rawClockTransaction/rawClockSelectedEmployeeSetup';

/* IndexDB Wizard object store names */
// Process Wizard
export const PROCESS_WIZARD_SUMMARY_BAR_STATE = 'process-wizard-summary-bar-state';

export const RAW_CLOCK_SELECTED_EMPLOYEE_SETUP = 'raw-clock-selected-employee-setup';

export const SELECTED_CLOCK_IN_IDS = 'selected-clock-in-ids';
export const SELECTED_CLOCK_OUT_IDS = 'selected-clock-out-ids';
export const SELECTED_CLOCK_AGGREGATES = 'selected-clock-aggregates';
export const SELECTED_GROUPED_CLOCK_AGGREGATES = 'selected-grouped-clock-aggregates';

export const SELECTED_INCENTIVE_IDS = 'selected-incentive-ids';
export const SELECTED_INCENTIVE_AGGREGATES = 'selected-incentive-aggregates';

/*================================================================================================================
 *                                   Process Wizard IndexedDB Stores
 * ==============================================================================================================*/

export const processWizardIndexedDBStores = (db : UpgradeDB) : void => {
    if (!db.objectStoreNames.contains(PROCESS_WIZARD_SUMMARY_BAR_STATE)) {
        db.createObjectStore<IWizardSummaryBarState, string>(PROCESS_WIZARD_SUMMARY_BAR_STATE);
    }
    
    if (!db.objectStoreNames.contains(SELECTED_CLOCK_IN_IDS)) {
        db.createObjectStore<number, string>(SELECTED_CLOCK_IN_IDS);
    }
    
    if (!db.objectStoreNames.contains(SELECTED_CLOCK_OUT_IDS)) {
        db.createObjectStore<number, string>(SELECTED_CLOCK_OUT_IDS);
    }
    
    if (!db.objectStoreNames.contains(SELECTED_CLOCK_AGGREGATES)) {
        db.createObjectStore<IRawClockTransactionAggregateIndexedDBView, string>(SELECTED_CLOCK_AGGREGATES);
    }
    
    if (!db.objectStoreNames.contains(SELECTED_GROUPED_CLOCK_AGGREGATES)) {
        db.createObjectStore<IRawClockEmployeeGroupedAggregateIndexedDBView, string>(SELECTED_GROUPED_CLOCK_AGGREGATES);
    }    

    if (!db.objectStoreNames.contains(SELECTED_INCENTIVE_IDS)) {
        db.createObjectStore<number, string>(SELECTED_INCENTIVE_IDS);
    }

    if (!db.objectStoreNames.contains(SELECTED_INCENTIVE_AGGREGATES)) {
        db.createObjectStore<IPieceWorkTransactionAggregateIndexedDBView, string>(SELECTED_INCENTIVE_AGGREGATES);
    }

    if (!db.objectStoreNames.contains(RAW_CLOCK_SELECTED_EMPLOYEE_SETUP)) {
        db.createObjectStore<IRawClockSelectedEmployeeSetup, string>(RAW_CLOCK_SELECTED_EMPLOYEE_SETUP);
    }
};

/*================================================================================================================
*                                                Cleanup Function
 * ==============================================================================================================*/

export const clearProcessWizardIndexedDBObjectStores = async () : Promise<void> => {
    const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

    const tx : Transaction = db.transaction([
        SELECTED_CLOCK_IN_IDS,
        SELECTED_CLOCK_OUT_IDS,
        SELECTED_CLOCK_AGGREGATES,
        SELECTED_GROUPED_CLOCK_AGGREGATES,
        SELECTED_INCENTIVE_IDS,
        SELECTED_INCENTIVE_AGGREGATES,
        RAW_CLOCK_SELECTED_EMPLOYEE_SETUP,
    ], 'readwrite');

    const rawClockSelectedEmployeeSetupsStore = tx.objectStore(RAW_CLOCK_SELECTED_EMPLOYEE_SETUP);
    const selectedClockInIdsStore = tx.objectStore(SELECTED_CLOCK_IN_IDS);
    const selectedClockOutIdsStore = tx.objectStore(SELECTED_CLOCK_OUT_IDS);
    const selectedClockAggregateStore = tx.objectStore(SELECTED_CLOCK_AGGREGATES);
    const groupedClockAggregateStore = tx.objectStore(SELECTED_GROUPED_CLOCK_AGGREGATES);
    const selectedIncentiveIdsStore = tx.objectStore(SELECTED_INCENTIVE_IDS);
    const selectedIncentiveAggregateStore = tx.objectStore(SELECTED_INCENTIVE_AGGREGATES);

    await Promise.all([
        rawClockSelectedEmployeeSetupsStore.clear(),
        selectedClockInIdsStore.clear(),
        selectedClockOutIdsStore.clear(),
        selectedClockAggregateStore.clear(),
        groupedClockAggregateStore.clear(),
        selectedIncentiveIdsStore.clear(),
        selectedIncentiveAggregateStore.clear(),
    ]);
    await tx.complete;
};

/*================================================================================================================
 *                                   Process Wizard IndexedDB Getters And Setters
 * ==============================================================================================================*/

export const setProcessWizardSummaryBarStateIndexedDB = async (summaryBarState : IWizardSummaryBarState | null) : Promise<void> => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);
        
        const tx = db.transaction(PROCESS_WIZARD_SUMMARY_BAR_STATE, 'readwrite');
        const store = tx.objectStore(PROCESS_WIZARD_SUMMARY_BAR_STATE);

        await store.clear();

        if (summaryBarState) {
            store.put(summaryBarState, PROCESS_WIZARD_SUMMARY_BAR_STATE);
        } else {
            store.clear();
        }

        await tx.complete;
    }
};

export const getProcessWizardSummaryBarStateIndexedDB = async () : Promise<IWizardSummaryBarState | undefined> => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(PROCESS_WIZARD_SUMMARY_BAR_STATE, 'readonly');
        const result = await tx.objectStore<IWizardSummaryBarState>(PROCESS_WIZARD_SUMMARY_BAR_STATE).get(PROCESS_WIZARD_SUMMARY_BAR_STATE);

        await tx.complete;
        return result;
    }
};

export const setProcessWizardRawClockSelectedEmployeeSetupIndexedDB = async (data : Array<IRawClockSelectedEmployeeSetup>) : Promise<void> => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);
        
        const tx = db.transaction(RAW_CLOCK_SELECTED_EMPLOYEE_SETUP, 'readwrite');
        const store = tx.objectStore(RAW_CLOCK_SELECTED_EMPLOYEE_SETUP);

        await store.clear();

        data.forEach((x) => {
            store.put(x, x.guid.toString());
        });

        await tx.complete;
    }
};

export const getProcessWizardRawClockSelectedEmployeeSetupIndexedDB = async () : Promise<Array<IRawClockSelectedEmployeeSetup> | undefined> => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(RAW_CLOCK_SELECTED_EMPLOYEE_SETUP, 'readonly');
        const result = await tx.objectStore(RAW_CLOCK_SELECTED_EMPLOYEE_SETUP).getAll();

        await tx.complete;
        return result;
    }
};

export const setProcessWizardSelectedClockInIdsIndexedDB = async (clockInIds : Array<number>) : Promise<void> => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);
        
        const tx = db.transaction(SELECTED_CLOCK_IN_IDS, 'readwrite');
        const store = tx.objectStore(SELECTED_CLOCK_IN_IDS);

        await store.clear();

        clockInIds.forEach((x) => {
            store.put(x, x.toString());
        });

        await tx.complete;
    }
};

export const getProcessWizardSelectedClockInIdsIndexedDB = async () : Promise<Array<number> | undefined> => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(SELECTED_CLOCK_IN_IDS, 'readonly');
        const result = await tx.objectStore<number>(SELECTED_CLOCK_IN_IDS).getAll();

        await tx.complete;
        return result;
    }
};

export const setProcessWizardSelectedClockOutIdsIndexedDB = async (clockOutIds : Array<number>) : Promise<void> => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);
        
        const tx = db.transaction(SELECTED_CLOCK_OUT_IDS, 'readwrite');
        const store = tx.objectStore(SELECTED_CLOCK_OUT_IDS);

        await store.clear();

        clockOutIds.forEach((x) => {
            store.put(x, x.toString());
        });
        
        await tx.complete;
    }
};

export const getProcessWizardSelectedClockOutIdsIndexedDB = async () : Promise<Array<number> | undefined> => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(SELECTED_CLOCK_OUT_IDS, 'readonly');
        const result = await tx.objectStore<number>(SELECTED_CLOCK_OUT_IDS).getAll();

        await tx.complete;
        return result;
    }
};

export const setProcessWizardSelectedClockAggregatesIndexedDB = async (clockAggregates : Array<IRawClockTransactionAggregate>) : Promise<void> => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);
        
        const tx = db.transaction(SELECTED_CLOCK_AGGREGATES, 'readwrite');
        const store = tx.objectStore(SELECTED_CLOCK_AGGREGATES);

        await store.clear();

        clockAggregates.forEach((x) => {
            const data : IRawClockTransactionAggregateIndexedDBView = {
                ...x,
                clockedInDateTime: x.clockedInDateTime?.toString(),
                clockedOutDateTime: x.clockedOutDateTime?.toString(),
            };
            store.put(data, x.guid);
        });
        
        await tx.complete;
    }
};

export const getProcessWizardSelectedClockAggregatesIndexedDB = async () : Promise<Array<IRawClockTransactionAggregate> | undefined> => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(SELECTED_CLOCK_AGGREGATES, 'readonly');
        const result = await tx.objectStore<IRawClockTransactionAggregateIndexedDBView>(SELECTED_CLOCK_AGGREGATES).getAll();

        await tx.complete;
        return result.map((x) => {
            return {
                ...x,
                clockedInDateTime: (x.clockedInDateTime && x.clockedInDateTime !== '') ? moment(x.clockedInDateTime) : null,
                clockedOutDateTime: (x.clockedOutDateTime && x.clockedOutDateTime !== '') ? moment(x.clockedOutDateTime) : null,
            };
        });
    }
};

export const setProcessWizardSelectedGroupedClockAggregatesIndexedDB = async (groupedClockAggregates : Array<IRawClockEmployeeGroupedAggregate>) : Promise<void> => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);
        
        const tx = db.transaction(SELECTED_GROUPED_CLOCK_AGGREGATES, 'readwrite');
        const store = tx.objectStore(SELECTED_GROUPED_CLOCK_AGGREGATES);

        await store.clear();

        groupedClockAggregates.forEach((x) => {
            const data : IRawClockEmployeeGroupedAggregateIndexedDBView = {
                ...x,
                date: x.date.toString(),
            };
            store.put(data, x.guid);
        });

        await tx.complete;
    }
};

export const getProcessWizardSelectedGroupedClockAggregatesIndexedDB = async () : Promise<Array<IRawClockEmployeeGroupedAggregate> | undefined> => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(SELECTED_GROUPED_CLOCK_AGGREGATES, 'readonly');
        const result = await tx.objectStore<IRawClockEmployeeGroupedAggregateIndexedDBView>(SELECTED_GROUPED_CLOCK_AGGREGATES).getAll();

        await tx.complete;
        return result.map((x) => {
            return {
                ...x,
                date: moment(x.date),
            };
        });
    }
};

export const setProcessWizardSelectedIncentiveIdsIndexedDB = async (incentiveIds : Array<number>) : Promise<void> => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);
        
        const tx = db.transaction(SELECTED_INCENTIVE_IDS, 'readwrite');
        const store = tx.objectStore(SELECTED_INCENTIVE_IDS);

        await store.clear();

        incentiveIds.forEach((x) => {
            store.put(x, x.toString());
        });
        
        await tx.complete;
    }
};

export const getProcessWizardSelectedIncentiveIdsIndexedDB = async () : Promise<Array<number> | undefined> => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(SELECTED_INCENTIVE_IDS, 'readonly');
        const result = await tx.objectStore<number>(SELECTED_INCENTIVE_IDS).getAll();

        await tx.complete;
        return result;
    }
};

export const setProcessWizardSelectedIncentiveAggregatesIndexedDB = async (incentiveAggregates : Array<IPieceWorkTransactionAggregate>) : Promise<void> => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);
        
        const tx = db.transaction(SELECTED_INCENTIVE_AGGREGATES, 'readwrite');
        const store = tx.objectStore(SELECTED_INCENTIVE_AGGREGATES);

        await store.clear();

        incentiveAggregates.forEach((x) => {
            const data : IPieceWorkTransactionAggregateIndexedDBView = {
                ...x,
                transactionDate: x.transactionDate.toString(),
            };
            store.put(data, x.guid);
        });
        
        await tx.complete;
    }
};

export const getProcessWizardSelectedIncentiveAggregatesIndexedDB = async () : Promise<Array<IPieceWorkTransactionAggregate> | undefined> => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(SELECTED_INCENTIVE_AGGREGATES, 'readonly');
        const result = await tx.objectStore<IPieceWorkTransactionAggregateIndexedDBView>(SELECTED_INCENTIVE_AGGREGATES).getAll();

        await tx.complete;
        return result.map((x) => {
            return {
                ...x,
                transactionDate: moment(x.transactionDate),
            };
        });
    }
};
