import { createAction } from '@reduxjs/toolkit';
import { withPayloadType } from '../../service/helper/reduxHelper';
import { IBlock } from '../../@types/model/masterData/block/block';
import { IDepartment } from '../../@types/model/masterData/department/department';
import { IDivision } from '../../@types/model/masterData/division/division';
import { IField } from '../../@types/model/masterData/field/field';
import { IProductionUnit } from '../../@types/model/masterData/productionUnit/productionUnit';
import { ISubdivision } from '../../@types/model/masterData/subdivision/subdivision';
import { ICommodity } from '../../@types/model/masterData/commodity/commodity';
import { IProject } from '../../@types/model/masterData/project/project';
import { IVariety } from '../../@types/model/masterData/variety/variety';
import { ICountry } from '../../@types/model/masterData/country/country';
import { IContactUs } from '../../@types/model/masterData/contactUs/contactUs';
import { INews } from '../../@types/model/masterData/news/news';
import { IActivity } from '../../@types/model/masterData/activity/activity';
import { ICrop } from '../../@types/model/masterData/crop/crop';
import { ICropLine } from '../../@types/model/masterData/cropLine/cropLine';
import { IRootStock } from '../../@types/model/masterData/rootStock/rootStock';
import { IClockingMethod } from '../../@types/model/masterData/clockingMethod/clockingMethod';
import { IClockingSystem } from '../../@types/model/masterData/clockingSystem/clockingSystem';
import { IEmployeeSetup } from '../../@types/model/masterData/employeeSetup/employeeSetup';
import { IDivisionDayOfWeek } from '../../@types/model/masterData/divisionDayOfWeek/divisionDayOfWeek';
import { IEmployee } from '../../@types/model/masterData/employee/employee';
import { IEmployeeRate } from '../../@types/model/masterData/employeeRate/employeeRate';
import { IJobType } from '../../@types/model/masterData/jobType/jobType';
import { IMinimumWageHistory } from '../../@types/model/masterData/minimumWageHistory/minimumWageHistory';
import { IPublicHoliday } from '../../@types/model/masterData/publicHoliday/publicHoliday';
import { IScanType } from '../../@types/model/masterData/scanType/scanType';
import { ITariffCalculation } from '../../@types/model/masterData/tariffCalculation/tariffCalculation';
import { IMasterDataSync } from '../../@types/model/sync/masterDataSync';
import { INorm } from '../../@types/model/masterData/norm/norm';
import { IActivityType } from '../../@types/model/masterData/activityType/activityType';
import { ICountryPublicHolidayRule } from '../../@types/model/masterData/countryPublicHolidayRule/countryPublicHolidayRule';
import { IPayRun } from '../../@types/model/masterData/payRun/payRun';
import { IActivityRate } from '../../@types/model/masterData/activityRate/activityRate';
import { ICompanyReference } from '../../@types/model/masterData/companyReference/companyReference';
import { IBusinessRule } from '../../@types/model/masterData/businessRule/businessRule';
import { IGlCode } from '../../@types/model/masterData/glCode/glCode';
import { ITariffType } from '../../@types/model/masterData/tariffType/tariffType';
import { ICapital } from '../../@types/model/masterData/capital/capital';

export default class DataActions {
    // states
    public static setIsMasterDataSyncRunning = createAction('DATA_SET_MASTER_DATA_SYNC_IS_LOADING', withPayloadType<boolean>());
    public static setIsLoading = createAction('DATA_SET_LOADING', withPayloadType<boolean>());
    
    // master data elements
    public static setActivityTypeData = createAction('DATA_SET_ACTIVITY_TYPE_LIST', withPayloadType<Array<IActivityType> | null>());
    public static setActivityData = createAction('DATA_SET_ACTIVITY_LIST', withPayloadType<Array<IActivity> | null>());
    public static setBlockData = createAction('DATA_SET_BLOCK_LIST', withPayloadType<Array<IBlock> | null>());
    public static setClockingMethodData = createAction('DATA_SET_CLOCKING_METHOD_LIST', withPayloadType<Array<IClockingMethod> | null>());
    public static setClockingSystemData = createAction('DATA_SET_CLOCKING_SYSTEM_LIST', withPayloadType<Array<IClockingSystem> | null>());
    public static setEmployeeSetupData = createAction('DATA_SET_EMPLOYEE_SETUP_LIST', withPayloadType<Array<IEmployeeSetup> | null>());
    public static setCommodityData = createAction('DATA_SET_COMMODITY_LIST', withPayloadType<Array<ICommodity> | null>());
    public static setContactUsData = createAction('DATA_SET_CONTACT_US_LIST', withPayloadType<Array<IContactUs> | null>());
    public static setCountryData = createAction('DATA_SET_COUNTRY_LIST', withPayloadType<Array<ICountry> | null>());
    public static setCropData = createAction('DATA_SET_CROP_LIST', withPayloadType<Array<ICrop> | null>());
    public static setCropLineData = createAction('DATA_SET_CROP_LINE_LIST', withPayloadType<Array<ICropLine> | null>());
    public static setDepartmentData = createAction('DATA_SET_DEPARTMENT_LIST', withPayloadType<Array<IDepartment> | null>());
    public static setDivisionData = createAction('DATA_SET_DIVISION_LIST', withPayloadType<Array<IDivision> | null>());
    public static setDivisionDayOfWeekData = createAction('DATA_SET_DIVISION_DAY_OF_WEEK_LIST', withPayloadType<Array<IDivisionDayOfWeek> | null>());
    public static setEmployeeData = createAction('DATA_SET_EMPLOYEE_LIST', withPayloadType<Array<IEmployee> | null>());
    public static setEmployeeRateData = createAction('DATA_SET_EMPLOYEE_RATE_LIST', withPayloadType<Array<IEmployeeRate> | null>());
    public static setFieldData = createAction('DATA_SET_FIELD_LIST', withPayloadType<Array<IField> | null>());
    public static setJobTypeData = createAction('DATA_SET_JOB_TYPE_LIST', withPayloadType<Array<IJobType> | null>());
    public static setMinimumWageHistoryData = createAction('DATA_SET_MINIMUM_WAGE_HISTORY_LIST', withPayloadType<Array<IMinimumWageHistory> | null>());
    public static setNewsData = createAction('DATA_SET_NEWS_LIST', withPayloadType<Array<INews> | null>());
    public static setProductionUnitData = createAction('DATA_SET_PRODUCTION_UNIT_LIST', withPayloadType<Array<IProductionUnit> | null>());
    public static setProjectData = createAction('DATA_SET_PROJECT_LIST', withPayloadType<Array<IProject> | null>());
    public static setPublicHolidayData = createAction('DATA_SET_PUBLIC_HOLIDAY_LIST', withPayloadType<Array<IPublicHoliday> | null>());
    public static setRootStockData = createAction('DATA_SET_ROOT_STOCK_LIST', withPayloadType<Array<IRootStock> | null>());
    public static setScanTypeData = createAction('DATA_SET_SCAN_TYPE_LIST', withPayloadType<Array<IScanType> | null>());
    public static setSubdivisionData = createAction('DATA_SET_SUBDIVISION_LIST', withPayloadType<Array<ISubdivision> | null>());
    public static setTariffCalculationData = createAction('DATA_SET_TARIFF_CALCULATION_LIST', withPayloadType<Array<ITariffCalculation> | null>());
    public static setNormData = createAction('DATA_SET_NORM_LIST', withPayloadType<Array<INorm> | null>());
    public static setTariffTypeData = createAction('DATA_SET_TARIFF_TYPE_LIST', withPayloadType<Array<ITariffType> | null>());
    public static setVarietyData = createAction('DATA_SET_VARIETIES_LIST', withPayloadType<Array<IVariety> | null>());
    public static setCountryPublicHolidayRuleData = createAction('DATA_SET_COUNTRY_PUBLIC_HOLIDAY_RULE_LIST', withPayloadType<Array<ICountryPublicHolidayRule> | null>());
    public static setPayRunData = createAction('DATA_SET_PAY_RUN_LIST', withPayloadType<Array<IPayRun> | null>());
    public static setActivityRateData = createAction('DATA_SET_ACTIVITY_RATE_LIST', withPayloadType<Array<IActivityRate> | null>());
    public static setCompanyReferenceData = createAction('DATA_SET_COMPANY_REFERENCE_LIST', withPayloadType<Array<ICompanyReference> | null>());
    public static setBusinessRuleData = createAction('DATA_SET_BUSINESS_RULE_LIST', withPayloadType<Array<IBusinessRule> | null>());
    public static setGlCodeData = createAction('DATA_SET_GL_CODE_LIST', withPayloadType<Array<IGlCode> | null>());
    public static setCapitalData = createAction('DATA_SET_CAPITAL_LIST', withPayloadType<Array<ICapital> | null>());


    // sync master data
    public static setSyncedMasterData = createAction('DATA_SET_SYNCED_MASTER_DATA', withPayloadType<IMasterDataSync>());

    // user selected data
    public static setSelectedUserDivisionIds = createAction('DATA_SET_USER_SELECTED_DIVISION_IDS', withPayloadType<Array<number> | null>());
    public static setSelectedUserDepartmentIds = createAction('DATA_SET_USER_SELECTED_DEPARTMENT_IDS', withPayloadType<Array<number> | null>());
    public static setSelectedUserDateFormat = createAction('DATA_SET_USER_SELECTED_DATE_FORMAT', withPayloadType<string | null>());

    // data reset
    public static reset = createAction('DATA_RESET');
}
