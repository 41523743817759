import axios, { AxiosPromise, Canceler, CancelTokenStatic } from 'axios';
import { IRootStock } from '../../../@types/model/masterData/rootStock/rootStock';
import { IRootStockUpsert } from '../../../@types/model/masterData/rootStock/rootStockUpsert';

export default class RootStockHttpService {
    private static readonly CancelToken : CancelTokenStatic = axios.CancelToken;
    /**
     * Allows you to cancel list request
     */
    public static cancelGetList ?: Canceler;

    public static masterDataRootStockGetList() : AxiosPromise<Array<IRootStock>> {
        if (this.cancelGetList) {
            this.cancelGetList('GET Canceled');
            this.cancelGetList = undefined;
        }

        return axios.get(`${API_URL}/v1/MasterData/RootStock/GetList`, {
            cancelToken: new this.CancelToken((c) => {
                this.cancelGetList = c;
            }),
        });
    }

    public static rootStockUpsert = (rootStockUpsert : IRootStockUpsert, type : 'Add' | 'Edit') : AxiosPromise<IRootStock> => {
        return axios.post(`${API_URL}/v1/MasterData/RootStock/${type}`, rootStockUpsert);
    }

    public static rootStockDelete = (rootStockId : number) : AxiosPromise<IRootStock> => {
        return axios.delete(`${API_URL}/v1/MasterData/RootStock/Delete`, {
            params: { rootStockId },
        });
    }
}