import axios, { AxiosPromise, Canceler, CancelTokenStatic } from 'axios';
import { IMasterDataSync } from '../../../@types/model/sync/masterDataSync';

export default class SyncHttpService {
    private static readonly CancelToken : CancelTokenStatic = axios.CancelToken;
    /**
     * Allows you to cancel list request
     */
    public static cancelGetList ?: Canceler;

    public static masterDataSyncAll(lastUpdatedUnix : number | null) : AxiosPromise<IMasterDataSync> {
        if (this.cancelGetList) {
            this.cancelGetList('GET Canceled');
            this.cancelGetList = undefined;
        }

        return axios.get(`${API_URL}/MasterData/Sync/All`, {
            params: {
                lastUpdatedUnix
            },
            cancelToken: new this.CancelToken((c) => {
                this.cancelGetList = c;
            })
        });
    }
}