import axios, { AxiosPromise, Canceler, CancelTokenStatic } from 'axios';
import { IBlock } from '../../../@types/model/masterData/block/block';
import { IBlockUpsert } from '../../../@types/model/masterData/block/blockUpsert';

export default class BlockHttpService {
    private static readonly CancelToken : CancelTokenStatic = axios.CancelToken;
    public static cancelGetSingleObject ?: Canceler;
    /**
     * Allows you to cancel list request
     */
    public static cancelGetList ?: Canceler;

    public static masterDataBlockGetList(departmentIds : Array<number>, divisionId ?: number, productionUnitId ?: number, fieldId ?: number, projectId ?: number) : AxiosPromise<Array<IBlock>> {
        if (this.cancelGetList) {
            this.cancelGetList('GET Canceled');
            this.cancelGetList = undefined;
        }

        return axios.get(`${API_URL}/v1/MasterData/Block/GetList`, {
            params: {
                departmentIds,
                divisionId,
                productionUnitId,
                fieldId,
                projectId,
            },
            paramsSerializer: {
                indexes: null
            },
            cancelToken: new this.CancelToken((c) => {
                this.cancelGetList = c;
            }),
        });
    }

    public static masterDataGetBlock(blockId : number) : AxiosPromise<IBlock> {
        if (this.cancelGetSingleObject) {
            this.cancelGetSingleObject('GET Canceled');
            this.cancelGetSingleObject = undefined;
        }

        return axios.get(`${API_URL}/v1/MasterData/Block/GetBlock`, {
            params: { blockId },
            cancelToken: new this.CancelToken((c) => {
                this.cancelGetSingleObject = c;
            }),
        });
    }

    public static blockUpsert = (blockUpsert : IBlockUpsert, type : 'Add' | 'Edit') : AxiosPromise<Array<IBlock>> => {
        return axios.post(`${API_URL}/v1/MasterData/Block/${type}`, blockUpsert);
    };

    public static blockDelete = (blockId : number) : AxiosPromise<IBlock> => {
        return axios.delete(`${API_URL}/v1/MasterData/Block/Delete`, {
            params: { blockId },
        });
    };
}