import { createReducer } from '@reduxjs/toolkit';
import { IPaymentTransaction } from '../../@types/model/paymentTransactions/paymentTransaction';
import PaymentTransactionActions from './actions';
import { IPaymentTransactionAudit } from '../../@types/model/paymentTransactions/paymentTransactionAudit';

export interface IPaymentTransactionState {
    isLoading : boolean;
    paymentTransactionList : Array<IPaymentTransaction> | null;
    paymentTransactionAuditLogs : Array<IPaymentTransactionAudit> | null;
}

const initialState = {
    isLoading: false,
    paymentTransactionList: null,
    paymentTransactionAuditLogs: null,
};

const paymentReducer = createReducer<IPaymentTransactionState>(initialState, (builder) => {
    builder.addCase(PaymentTransactionActions.setIsLoading, (state, action) => {
        state.isLoading = action.payload;
    });
    builder.addCase(PaymentTransactionActions.setPaymentTransactionData, (state, action) => {
        state.paymentTransactionList = action.payload;
    });
    builder.addCase(PaymentTransactionActions.setPaymentTransactionAuditData, (state, action) => {
        state.paymentTransactionAuditLogs = action.payload;
    });
    builder.addCase(PaymentTransactionActions.reset, () => {
        return initialState;
    });
});

export default paymentReducer;
