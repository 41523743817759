import axios, { AxiosPromise, Canceler, CancelTokenStatic } from 'axios';
import { ICropLine } from '../../../@types/model/masterData/cropLine/cropLine';

export default class CropLineHttpService {
    private static readonly CancelToken : CancelTokenStatic = axios.CancelToken;
    /**
     * Allows you to cancel list request
     */
    public static cancelGetList ?: Canceler;

    public static masterDataCropLineGetList(cropIds ?: Array<number>) : AxiosPromise<Array<ICropLine>> {
        if (this.cancelGetList) {
            this.cancelGetList('GET Canceled');
            this.cancelGetList = undefined;
        }

        return axios.get(`${API_URL}/v1/MasterData/CropLine/GetList`, {
            params: {
                cropIds,
            },
            paramsSerializer: {
                indexes: null
            },
            cancelToken: new this.CancelToken((c) => {
                this.cancelGetList = c;
            }),
        });
    }
}