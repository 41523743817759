import axios, { AxiosPromise, Canceler, CancelTokenStatic } from 'axios';
import { ICountry } from '../../../@types/model/masterData/country/country';
import { ICountryUpsert } from '../../../@types/model/masterData/country/countryUpsert';

export default class CountryHttpService {
    private static readonly CancelToken : CancelTokenStatic = axios.CancelToken;
    /**
     * Allows you to cancel list request
     */
    public static cancelGetList ?: Canceler;

    public static masterDataCountryGetList() : AxiosPromise<Array<ICountry>> {
        if (this.cancelGetList) {
            this.cancelGetList('GET Canceled');
            this.cancelGetList = undefined;
        }

        return axios.get(`${API_URL}/v1/MasterData/Country/GetList`, {
            cancelToken: new this.CancelToken((c) => {
                this.cancelGetList = c;
            }),
        });
    }

    public static countryUpsert = (countryUpsert : ICountryUpsert, type : 'Add' | 'Edit') : AxiosPromise<ICountry> => {
        return axios.post(`${API_URL}/v1/MasterData/Country/${type}`, countryUpsert);
    }

    public static countryDelete = (countryId : number) : AxiosPromise<ICountry> => {
        return axios.delete(`${API_URL}/v1/MasterData/Country/Delete`, {
            params: { countryId },
        });
    }
}