import { IRight } from './auth/right/right';
import { IRole } from './auth/role/role';
import { IActivity } from './masterData/activity/activity';
import { IBlock } from './masterData/block/block';
import { IClockingMethod } from './masterData/clockingMethod/clockingMethod';
import { IClockingSystem } from './masterData/clockingSystem/clockingSystem';
import { ICommodity } from './masterData/commodity/commodity';
import { ICountry } from './masterData/country/country';
import { ICropLine } from './masterData/cropLine/cropLine';
import { IDepartment } from './masterData/department/department';
import { IDivision } from './masterData/division/division';
import { IEmployee } from './masterData/employee/employee';
import { IField } from './masterData/field/field';
import { IJob } from './job/job';
import { IJobType } from './masterData/jobType/jobType';
import { IProductionUnit } from './masterData/productionUnit/productionUnit';
import { IProject } from './masterData/project/project';
import { IPublicHoliday } from './masterData/publicHoliday/publicHoliday';
import { IRawClockPhoto } from './rawClock/rawClockPhoto/rawClockPhoto';
import { ISubdivision } from './masterData/subdivision/subdivision';
import { IVariety } from './masterData/variety/variety';
import { IPayRun } from './masterData/payRun/payRun';
import { IPaymentTransaction } from './paymentTransactions/paymentTransaction';
import { IActivityType } from './masterData/activityType/activityType';
import { IRootStock } from './masterData/rootStock/rootStock';
import { ICompanyReference } from './masterData/companyReference/companyReference';
import { ITariffType } from './masterData/tariffType/tariffType';
import { IOptionType } from '@zz2/zz2-ui';
import { IGlCode } from './masterData/glCode/glCode';

export class OptionType {

    /*================================================================================================================
     *                                                  Master Data
     * ==============================================================================================================*/

    public static fromCountry = (country : ICountry) : IOptionType => {
        return {
            label: `(${country.alpha3Code}) ${country.name}`,
            value: country.id,
        };
    };

    public static fromDivision = (division : IDivision) : IOptionType => {
        return {
            label: division.name,
            value: division.id,
        };
    };

    public static fromSubdivision = (subdivision : ISubdivision) : IOptionType => {
        return {
            label: subdivision.name,
            value: subdivision.id,
        };
    };

    public static fromDepartment = (department : IDepartment) : IOptionType => {
        return {
            label: `(${department.code})${department.shortName ? ` - ${department.shortName}` : ''} - ${department.name}`,
            value: department.id,
        };
    };

    public static fromProductionUnit = (productionUnit : IProductionUnit) : IOptionType => {
        return {
            label: productionUnit.name,
            value: productionUnit.id,
        };
    };

    public static fromField = (field : IField) : IOptionType => {
        return {
            label: `(${field.id}) ${field.name}`,
            value: field.id,
        };
    };

    public static fromBlock = (block : IBlock) : IOptionType => {
        return {
            label: block.code,
            value: block.id,
        };
    };

    public static fromCommodity = (commodity : ICommodity) : IOptionType => {
        return {
            label: commodity.name,
            value: commodity.id,
        };
    };

    public static fromVariety = (variety : IVariety) : IOptionType => {
        return {
            label: variety.name,
            value: variety.id,
        };
    };

    public static fromRootStock = (rootStock : IRootStock) : IOptionType => {
        return {
            label: rootStock.name,
            value: rootStock.id,
        };
    };

    public static fromProject = (project : IProject) : IOptionType => {
        return {
            label: `(${project.number}) ${project.description}`,
            value: project.id,
        };
    };

    public static fromEmployee = (employee : IEmployee) : IOptionType => {
        return {
            label: `(${employee.number}) ${employee.fullName}`,
            value: employee.id,
        };
    };

    public static fromPublicHoliday = (publicHoliday : IPublicHoliday) : IOptionType => {
        return {
            label: `(${publicHoliday.code}) ${publicHoliday.shortDescription}`,
            value: publicHoliday.id,
        };
    };

    public static fromTariffType = (tariffType : ITariffType) : IOptionType => {
        return {
            label: `(${tariffType.code}) ${tariffType.name}`,
            value: tariffType.id,
        };
    };

    public static fromActivity = (activity : IActivity) : IOptionType => {
        return {
            label: `(${activity.code}) ${activity.description}`,
            value: activity.id,
        };
    };

    public static fromActivityType = (activityType : IActivityType) : IOptionType => {
        return {
            label: `(${activityType.code}) ${activityType.name}`,
            value: activityType.id,
        };
    };

    public static fromClockingSystem = (clockingSystem : IClockingSystem) : IOptionType => {
        return {
            label: `(${clockingSystem.code}) ${clockingSystem.description}`,
            value: clockingSystem.id,
        };
    };

    public static fromClockingMethod = (clockingMethod : IClockingMethod) : IOptionType => {
        return {
            label: `(${clockingMethod.code}) ${clockingMethod.name}`,
            value: clockingMethod.id,
        };
    };

    public static fromJobType = (jobType : IJobType) : IOptionType => {
        return {
            label: `(${jobType.code}) ${jobType.name}`,
            value: jobType.id,
        };
    };

    public static fromCropLine = (cropLine : ICropLine) : IOptionType => {
        return {
            label: `(${cropLine.cropId}) - CropLine`,
            value: cropLine.id,
        };
    };

    public static fromPayRun = (payRun : IPayRun) : IOptionType => {
        return {
            label: payRun.payRunReference,
            value: payRun.id,
        };
    };

    public static fromCompanyReference = (companyReference : ICompanyReference) : IOptionType => {
        return {
            label: `(${companyReference.code}) - ${companyReference.description}`,
            value: companyReference.id,
        };
    };

    public static fromGlCode = (glCode : IGlCode) : IOptionType => {
        return {
            label: `(${glCode.code}) - ${glCode.description}`,
            value: glCode.id,
        };
    };

    /*================================================================================================================
     *                                                  Raw Clock
     * ==============================================================================================================*/

    public static fromRawClockPhoto = (photo : IRawClockPhoto) : IOptionType => {
        return {
            label: `(${photo.id}) - Photo`,
            value: photo.id,
        };
    };

    /*================================================================================================================
     *                                                  Job
     * ==============================================================================================================*/

    public static fromJob = (job : IJob) : IOptionType => {
        return {
            label: `(${job.id}) Job`,
            value: job.id,
        };
    };

    /*================================================================================================================
     *                                                  Payment Transaction
     * ==============================================================================================================*/

    public static fromPaymentTransaction = (paymentTransaction : IPaymentTransaction) : IOptionType => {
        return {
            label: `(${paymentTransaction.id}) - Transaction`,
            value: paymentTransaction.id,
        };
    };

    /*================================================================================================================
     *                                                  Auth
     * ==============================================================================================================*/

    public static fromRight = (right : IRight) : IOptionType => {
        return {
            label: `(${right.code}) ${right.name}`,
            value: right.id,
        };
    };

    public static fromRole = (role : IRole) : IOptionType => {
        return {
            label: role.name,
            value: role.id,
        };
    };
}
