import React, { Suspense } from 'react';
import { Redirect, Route, RouteComponentProps, RouteProps, Switch } from 'react-router';
import { useAppSelector } from '../../@types/redux';
import { RIGHT_GRANTS } from '../../appConstants';
import NoRightsScreen from '../right/NoRightsScreen';
import { IUserRight } from '../../@types/model/auth/user/UserRight';
import { Loader, Screen } from '@zz2/zz2-ui';

const PrivateRoute = (props : RouteProps) : JSX.Element => {
    const session = useAppSelector(x => x.auth.session);
    const { component, ...rest } = props;

    /**
     * Check if current user is associated with a right that corresponds to the destination url.
     * @param url
     */
    const hasMenuRight = (url : string) : boolean => {
        if (!session) return false;
        if (session.user.rights.length < 1) return false;

        const userRights : Array<IUserRight> = session.user.userRights;
        if (userRights.length < 1) {
            return false;
        } else {
            const filteredUserRights : Array<IUserRight> = userRights.filter(x => !!x.isActive && x.right.url === url);

            if (filteredUserRights.length > 0 && filteredUserRights.some(x => x.rightGrantLevel === RIGHT_GRANTS.View)) {
                return true;
            } else {
                return false;
            }
        }
    };

    const render = (routeProps : RouteComponentProps<{ [x : string] : string | undefined }>) : React.ReactNode => {
        if (session) {
            if (!component) {
                return;
            }
            const Component = component;
            if (!hasMenuRight(routeProps.match.url)) {
                return <NoRightsScreen />;
            }
            return <Component {...routeProps} />;
        }
        return <Redirect to={{ pathname: '/login', state: { from: routeProps.location } }} />;
    };
    return (<Route {...rest} render={render} />);
};

{/* Home */}
const Home = React.lazy(() => import('../home/HomeScreen'));

{/* Master Data */}
const MasterDataManager = React.lazy(() => import('../masterData/MasterDataManager'));
const CountryManager = React.lazy(() => import('../masterData/country/CountryManager'));
const CountryPublicHolidayRuleManager = React.lazy(() => import('../masterData/countryPublicHolidayRule/CountryPublicHolidayRuleManager'));
const DivisionManager = React.lazy(() => import('../masterData/division/DivisionManager'));
const SubdivisionManager = React.lazy(() => import('../masterData/subdivision/SubdivisionManager'));
const DepartmentManager = React.lazy(() => import('../masterData/department/DepartmentManager'));
const ProductionUnitManager = React.lazy(() => import('../masterData/productionUnit/ProductionUnitManager'));
const FieldManager = React.lazy(() => import('../masterData/field/FieldManager'));
const BlockManager = React.lazy(() => import('../masterData/block/BlockManager'));
const CommodityManager = React.lazy(() => import('../masterData/commodity/CommodityManager'));
const VarietyManager = React.lazy(() => import('../masterData/variety/VarietyManager'));
const ProjectManager = React.lazy(() => import('../masterData/project/ProjectManager'));
const RootStockManager = React.lazy(() => import('../masterData/rootStock/RootStockManager'));
const CropManager = React.lazy(() => import('../masterData/crop/CropManager'));
const ActivityTypeManager = React.lazy(() => import('../masterData/activityType/ActivityTypeManager'));
const ActivityManager = React.lazy(() => import('../masterData/activity/ActivityManager'));
const ContactUsManager = React.lazy(() => import('../masterData/contactUs/ContactUsManager'));
const ClockingMethodManager = React.lazy(() => import('../masterData/clockingMethod/ClockingMethodManager'));
const ClockingSystemManager = React.lazy(() => import('../masterData/clockingSystem/ClockingSystemManager'));
const EmployeeSetupManager = React.lazy(() => import('../masterData/employeeSetup/EmployeeSetupManager'));
const DivisionDayOfWeekManager = React.lazy(() => import('../masterData/divisionDayOfWeek/DivisionDayOfWeekManager'));
const EmployeeManager = React.lazy(() => import('../masterData/employee/EmployeeManager'));
const EmployeeRateManager = React.lazy(() => import('../masterData/employeeRate/EmployeeRateManager'));
const JobTypeManager = React.lazy(() => import('../masterData/jobType/JobTypeManager'));
const MinimumWageHistoryManager = React.lazy(() => import('../masterData/minimumWageHistory/MinimumWageHistoryManager'));
const PublicHolidayManager = React.lazy(() => import('../masterData/publicHoliday/PublicHolidayManager'));
const ScanTypeManager = React.lazy(() => import('../masterData/scanType/ScanTypeManager'));
const TariffCalculationManager = React.lazy(() => import('../masterData/tariffCalculation/TariffCalculationManager'));
const TariffTypeManager = React.lazy(() => import('../masterData/tariffType/TariffTypeManager'));
const PayRunManager = React.lazy(() => import('../masterData/payRun/PayRunManager'));
const NormManager = React.lazy(() => import('../masterData/norm/NormManager'));
const ActivityRateManager = React.lazy(() => import('../masterData/activityRate/ActivityRateManager'));
const CompanyReferenceManager = React.lazy(() => import('../masterData/companyReference/CompanyReferenceManager'));
const BusinessRuleManager = React.lazy(() => import('../masterData/businessRule/BusinessRuleManager'));
const GlCodeManager = React.lazy(() => import('../masterData/glCode/GlCodeManager'));
const CapitalManager = React.lazy(() => import('../masterData/capital/CapitalManager'));

{/* Rights */}
const RightManager = React.lazy(() => import('../right/rightManagement/RightManager'));
const UserManager = React.lazy(() => import('../right/userManagement/UserManager'));

{/* Logging */}
const LogManager = React.lazy(() => import('../logging/LogManager'));
const FileDownloadLogManager = React.lazy(() => import('../fileDownloadLogs/FileDownloadLogManager'));

{/* Frequently Asked Questions */}
const FrequentlyAskedQuestionManager = React.lazy(() => import('../frequentlyAskedQuestions/FrequentlyAskedQuestionManager'));

{/* Processing */}
const ProcessingWizard = React.lazy(() => import('../processTransactions/processingWizard'));

{/* Raw Clock */}
const RawClockTransactionManager = React.lazy(() => import('../rawClock/RawClockTransactionManager'));

{/* Piece Work */}
const PieceWorkTransactionManager = React.lazy(() => import('../pieceWork/PieceWorkTransactionManager'));

{/* Jobs */}
const JobManager = React.lazy(() => import('../job/JobManager'));

{/* Payments */}
const PaymentTransactionManager = React.lazy(() => import('../paymentTransaction/PaymentTransactionManager'));
const DeductionManager = React.lazy(() => import('../deductions/DeductionManager'));

{/* Pay Run Extraction */}
const PayRunExtractionManager = React.lazy(() => import('../payRunExtractions/payRunExtractionManager'));

{/* Batches */}
const BatchWizard = React.lazy(() => import('../batch/wizard/BatchWizard'));
const BatchTransactionManager = React.lazy(() => import('../batch/batchTransactionManager'));
const BatchApprovalManager = React.lazy(() => import('../batch/approvals/BatchApprovalManager'));

{/* Pay Run */}
const PayRunWizard = React.lazy(() => import('../payRunExtractions/wizard/PayRunWizard'));


const Routes = () : JSX.Element => {
    return (
        <Suspense fallback={<Screen useAnimatedLoader><Loader/></Screen>}>
            <Switch>
                <Route path={'/faq'} exact component={FrequentlyAskedQuestionManager} />
                <Route
                    path={'/'} exact
                    render={() : JSX.Element => <Redirect from={'/'} to={{ pathname: '/home' }} />}
                />

                <PrivateRoute exact path='/home' component={Home} />

                {/* Master Data */}
                <PrivateRoute exact path={'/masterData'} component={MasterDataManager}/>
                <PrivateRoute exact path={'/masterData/contactUs'} component={ContactUsManager}/>
                <PrivateRoute exact path={'/masterData/country'} component={CountryManager}/>
                <PrivateRoute exact path={'/masterData/countryPublicHolidayRule'} component={CountryPublicHolidayRuleManager}/>
                <PrivateRoute exact path={'/masterData/division'} component={DivisionManager}/>
                <PrivateRoute exact path={'/masterData/subdivision'} component={SubdivisionManager}/>
                <PrivateRoute exact path={'/masterData/department'} component={DepartmentManager}/>
                <PrivateRoute exact path={'/masterData/productionUnit'} component={ProductionUnitManager}/>
                <PrivateRoute exact path={'/masterData/field'} component={FieldManager}/>
                <PrivateRoute exact path={'/masterData/block'} component={BlockManager}/>
                <PrivateRoute exact path={'/masterData/commodity'} component={CommodityManager}/>
                <PrivateRoute exact path={'/masterData/variety'} component={VarietyManager}/>
                <PrivateRoute exact path={'/masterData/project'} component={ProjectManager}/>
                <PrivateRoute exact path={'/masterData/rootStock'} component={RootStockManager}/>
                <PrivateRoute exact path={'/masterData/crop'} component={CropManager}/>
                <PrivateRoute exact path={'/masterData/activity'} component={ActivityManager}/>
                <PrivateRoute exact path={'/masterData/activityType'} component={ActivityTypeManager}/>
                <PrivateRoute exact path={'/masterData/clockingMethod'} component={ClockingMethodManager}/>
                <PrivateRoute exact path={'/masterData/clockingSystem'} component={ClockingSystemManager}/>
                <PrivateRoute exact path={'/masterData/divisionDayOfWeek'} component={DivisionDayOfWeekManager}/>
                <PrivateRoute exact path={'/masterData/employee'} component={EmployeeManager}/>
                <PrivateRoute exact path={'/masterData/employeeSetup'} component={EmployeeSetupManager}/>
                <PrivateRoute exact path={'/masterData/employeeRate'} component={EmployeeRateManager}/>
                <PrivateRoute exact path={'/masterData/jobType'} component={JobTypeManager}/>
                <PrivateRoute exact path={'/masterData/minimumWageHistory'} component={MinimumWageHistoryManager}/>
                <PrivateRoute exact path={'/masterData/publicHoliday'} component={PublicHolidayManager}/>
                <PrivateRoute exact path={'/masterData/scanType'} component={ScanTypeManager}/>
                <PrivateRoute exact path={'/masterData/tariffCalculation'} component={TariffCalculationManager}/>
                <PrivateRoute exact path={'/masterData/tariffType'} component={TariffTypeManager}/>
                <PrivateRoute exact path={'/masterData/payRun'} component={PayRunManager}/>
                <PrivateRoute exact path={'/masterData/norm'} component={NormManager}/>
                <PrivateRoute exact path={'/masterData/activityRate'} component={ActivityRateManager}/>
                <PrivateRoute exact path={'/masterData/companyReference'} component={CompanyReferenceManager}/>
                <PrivateRoute exact path={'/masterData/businessRule'} component={BusinessRuleManager}/>
                <PrivateRoute exact path={'/masterData/glCode'} component={GlCodeManager}/>
                <PrivateRoute exact path={'/masterData/capital'} component={CapitalManager}/>

                {/* Rights */}
                <PrivateRoute exact path='/Rights/rightManager' component={RightManager} />
                <PrivateRoute exact path='/Rights/userManager' component={UserManager} />

                {/* Development */}
                <PrivateRoute exact path = '/development/tools' component={LogManager} />
                
                {/* logging */}
                <PrivateRoute exact path = '/logging/fileDownloads' component={FileDownloadLogManager} />

                {/* Batches */}
                <PrivateRoute exact path = '/batch/wizard' component={BatchWizard} />
                <PrivateRoute exact path ={'/batch/transactions'} component={BatchTransactionManager}/>
                <PrivateRoute exact path ={'/batch/approvals'} component={BatchApprovalManager}/>

                {/* Payments */}
                <PrivateRoute exact path = '/payment/transactions' component={PaymentTransactionManager} />
                <PrivateRoute exact path = '/payment/deductions' component={DeductionManager} />

                {/* Process Transaction */}
                <PrivateRoute exact path={'/process/wizard'} component={ProcessingWizard}/>

                {/* Raw Clock */}
                <PrivateRoute exact path={'/process/rawClocks'} component={RawClockTransactionManager}/>

                {/* Piece Work */}
                <PrivateRoute exact path={'/process/pieceWork'} component={PieceWorkTransactionManager}/>

                {/* Job */}
                <PrivateRoute exact path={'/process/job'} component={JobManager}/>

                {/* Pay Run */}
                <PrivateRoute exact path={'/payRun/wizard'} component={PayRunWizard}/>

                {/* Pay Run Extraction */}
                <PrivateRoute exact path = '/payRun/extractions' component={PayRunExtractionManager} />
            </Switch>
        </Suspense>
    );
};

export default Routes;
