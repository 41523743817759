import { createReducer } from '@reduxjs/toolkit';
import { IFrequentlyAskedQuestion } from '../../@types/model/frequentlyAskedQuestions/frequentlyAskedQuestion';
import FrequentlyAskedQuestionActions from './actions';

export interface IFrequentlyAskedQuestionState {
    isLoading : boolean;
    frequentlyAskedQuestionList : Array<IFrequentlyAskedQuestion> | null;
}

const initialState = {
    isLoading: false,
    frequentlyAskedQuestionList: null,
};

const sensorReducer = createReducer<IFrequentlyAskedQuestionState>(initialState, (builder) => {
    builder.addCase(FrequentlyAskedQuestionActions.setIsLoading, (state, action) => {
        state.isLoading = action.payload;
    });
    builder.addCase(FrequentlyAskedQuestionActions.setFrequentlyAskedQuestionData, (state, action) => {
        state.frequentlyAskedQuestionList = action.payload;
    });
    builder.addCase(FrequentlyAskedQuestionActions.reset, () => {
        return initialState;
    });
});

export default sensorReducer;
