import React, { useEffect, useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../@types/redux';
import { getUserSelectedDepartmentsLocalStorage,
    getUserSelectedDivisionsLocalStorage,
    setUserSelectedDepartmentsLocalStorage,
    setUserSelectedDivisionsLocalStorage } from '../../service/localStorageService';
import { IUser } from '../../@types/model/auth/user/user';
import AuthThunk from '../../store/auth/thunk';
import MasterDataThunk from '../../store/masterData/thunk';
import DataActions from '../../store/masterData/actions';
import RightThunks from '../../store/right/thunk';
import generalActions from '../../store/general/actions';
import { CustomTooltip, Loader, NavBar, NavDrawer, SideMenuItem, lowercase } from '@zz2/zz2-ui';
import Routes from './Routes';
import FreshServiceActions from '../../store/freshService/actions';
import { VERSION } from '../../version';
import EnvHelper from '../../service/helper/envHelper';
import { IRight } from '../../@types/model/auth/right/right';
import UserHelper from '../../service/helper/userHelper';
import { RIGHT_GRANTS } from '../../appConstants';
import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import lodash from 'lodash';
import UserSettings from './UserSettings';

const iconsLocation = `${ASSET_BASE}/icons`;

const NavFrame = () : React.ReactElement => {
    const path = useLocation().pathname;
    const dispatch = useAppDispatch();
    const selectedDivisions = getUserSelectedDivisionsLocalStorage();
    const selectedDepartments = getUserSelectedDepartmentsLocalStorage();

    const currentUser : IUser | null = useAppSelector(x => x.auth.session?.user) ?? null;
    const isLoading = useAppSelector(x => x.right.isLoading);
    const isMasterDataSyncRunning = useAppSelector(x => x.masterData.masterDataSyncIsRunning);
    const divisions = useAppSelector(x => x.masterData.divisionData);
    const departments = useAppSelector(x => x.masterData.departmentData);
    const rightData = useAppSelector(x => x.right.rightData);
    const isNavDrawerOpen = useAppSelector(x => x.general.isNavDrawerOpen);

    const [isUserSettingsDialogOpen, setIsUserSettingsDialogOpen] = useState<boolean>(false);

    /*================================================================================================================
     *                                                  Effects
     * ==============================================================================================================*/

    useEffect(() => {
        loadData();
    }, [currentUser, divisions, departments]);

    useEffect(() => {
        if (!isNavDrawerOpen) return;
        loadRightData();
    }, [isNavDrawerOpen]);

    /*================================================================================================================
     *                                                  Async Methods
     * ==============================================================================================================*/

    const signOut = () : void => {
        dispatch(AuthThunk.logOut());
        closeDrawer();
    };

    const openUserSettings = async () : Promise<void> => {
        setIsUserSettingsDialogOpen(true);

        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (self.indexedDB) {
            dispatch(MasterDataThunk.syncMasterData());
        } else {
            dispatch(MasterDataThunk.getDivisionList(null));
            dispatch(MasterDataThunk.getDepartmentList(null));
        }
    };

    const loadData = () : void => {
        if (currentUser?.divisionIds && currentUser.divisionIds.length > 0) {
            if (selectedDivisions.length < 1) return;

            dispatch(DataActions.setSelectedUserDivisionIds(selectedDivisions.map(x => Number(x.value))));
        } else {
            setUserSelectedDivisionsLocalStorage([]);
            dispatch(DataActions.setSelectedUserDivisionIds(null));
        }

        if (currentUser?.departmentIds && currentUser.departmentIds.length > 0) {
            if (selectedDepartments.length < 1) return;

            dispatch(DataActions.setSelectedUserDepartmentIds(selectedDepartments.map(x => Number(x.value))));
        } else {
            setUserSelectedDepartmentsLocalStorage([]);
            dispatch(DataActions.setSelectedUserDepartmentIds(null));
        }

        if (currentUser && (!currentUser.divisionIds || !currentUser.departmentIds)) {
            setIsUserSettingsDialogOpen(true);
        }
    };

    const loadRightData = async () : Promise<void> => {
        await dispatch(RightThunks.getRightList());
    };

    /*================================================================================================================
     *                                                  Handler Methods
     * ==============================================================================================================*/

    const openDrawer = () : void => {
        dispatch(generalActions.setNavDrawer(true));
    };

    const closeDrawer = () : void => {
        dispatch(generalActions.setNavDrawer(false));
    };

    const closeUserSettings = () : void => {
        setIsUserSettingsDialogOpen(false);
    };

    const openSupportTicketDialog = () : void => {
        dispatch(FreshServiceActions.setIsSupportTicketDialogOpen(true));
    };

    const requestAccess = (right : IRight) : void => {
        const regex = new RegExp('/', 'g');
        const envTitle = ENV_NAME === 'production' ? 'E-Wages Website' : 'E-Wages QA Website';
        const title = (envTitle + '/' + VERSION.version).replace(regex, ' - ');

        dispatch(FreshServiceActions.setIsSupportTicketDialogOpen(true));
        dispatch(FreshServiceActions.setSupportTicketTitle(title));
        dispatch(FreshServiceActions.setSupportTicketDescription(`Requesting permission: ${right.name} - View.`));
    };

    const isParentOfCurrentPage = (parentId : number, rights ?: Array<IRight>, pathname ?: string) : boolean => {
        const currentPageRight = rights?.find(x => x.url === pathname);
        const parentRight = rights?.find(x => x.id === parentId);

        if (currentPageRight?.parentId) {
            return (currentPageRight.parentId === parentId) || (!!parentRight?.parentId && isParentOfCurrentPage(parentRight.parentId, rights, pathname));
        }
        return false;
    };

    const renderListSection = (right : IRight, children : Array<IRight>, rights ?: Array<IRight>, pathname ?: string) : JSX.Element => {
        const userRights = currentUser?.userRights;
        const hasViewRight = userRights?.some(x =>
            x.isActive
            && x.right.id === right.id
            && x.rightGrantLevel === RIGHT_GRANTS.View);

        return (
            <CustomTooltip
                title={!hasViewRight ? `Right: ${right.name}, level: View is required for access. \n\nClick to request permission.` : ''}
                onClick={() : void => requestAccess(right)}
                rightValidation={!hasViewRight}
                key={'section_tooltip_' + right.id}>
                <SideMenuItem
                    className={'pt10'}
                    paddingLeft={0}
                    icon={<img className={'h30 w30'} src={`${iconsLocation}/${lowercase(right.name).replace(/ /g, '_')}.svg`}/> }
                    title={right.name}
                    key={'section_' + right.id}
                    boldTitle={isParentOfCurrentPage(right.id, rights, pathname)}
                    disabled={!hasViewRight}>
                    {children.map(x => renderListItem(x, false, pathname))}
                </SideMenuItem>
            </CustomTooltip>
        );
    };

    const renderListItem = (right : IRight, hasIcon : boolean, pathname ?: string) : JSX.Element => {
        const userRights = currentUser?.userRights;
        const hasViewRight = userRights?.some(x =>
            x.isActive
            && x.right.id === right.id
            && x.rightGrantLevel === RIGHT_GRANTS.View);

        return (
            <CustomTooltip
                title={!hasViewRight ? `Right: ${right.name}, level: View is required for access. \n\nClick to request permission.` : ''}
                onClick={() : void => requestAccess(right)}
                rightValidation={!hasViewRight}
                key={'list_item_tooltip_' + right.guid}>
                <Link to={right.url} onClick={closeDrawer} style={{ pointerEvents: hasViewRight ? 'auto' : 'none', textDecoration: 'unset', color: 'unset' }}>
                    <ListItemButton
                        key={right.guid}
                        disabled={!hasViewRight}>
                        {
                            hasIcon ?
                                <ListItemIcon>
                                    {<img className={'h30 w30'} src={`${iconsLocation}/${lowercase(right.name).replace(/ /g, '_')}.svg`} />}
                                </ListItemIcon>
                                :
                                <div className={'h30 w30'} />
                        }
                        <ListItemText disableTypography className={`${pathname === right.url ? 'fw550' : ''} pl0`} inset primary={right.name} />
                    </ListItemButton>
                </Link>
            </CustomTooltip>
        );
    };

    /*================================================================================================================
     *                                                  Memos
     * ==============================================================================================================*/

    const rights = useMemo<Array<IRight>>(() => {
        return currentUser?.rights ?? [];
    }, [currentUser]);

    const userCurrentRight = useMemo<IRight | undefined>(() => {
        return currentUser?.rights.filter(x => x.isActive)?.find(x => x.url === path);
    }, [currentUser, path]);

    const breadcrumbs = useMemo(() => {
        const breadcrumbList : Array<{ name : string; url : string }> = [];

        if (userCurrentRight) {
            let currentRight : IRight | undefined = userCurrentRight;

            do {
                breadcrumbList.push({ name: currentRight.name, url: currentRight.url });
                currentRight = rights.find(x => x.id === currentRight?.breadcrumbParentId);
            } while (currentRight);
        }
        return breadcrumbList.reverse();
    }, [currentUser, path, rights, userCurrentRight]);

    const divisionHeading = useMemo<string>(() => {
        return UserHelper.getUserDivisionHeading(currentUser);
    }, [currentUser, selectedDivisions, isUserSettingsDialogOpen]);

    const departmentHeading = useMemo<string>(() => {
        return UserHelper.getUserDepartmentHeading(currentUser);
    }, [currentUser, selectedDivisions, selectedDepartments, isUserSettingsDialogOpen]);

    const sections = useMemo<Array<JSX.Element>>(() => {
        const currentUserRights = currentUser?.rights ?? [];

        if (isLoading) return [<Loader key={'loader'}/>];

        return lodash.chain(rightData)
            .filter(x => x.isOnNavDrawer && x.isActive && !x.parentId)
            .filter(x => !!x.sectionOrder)
            .sortBy(x => x.sectionOrder)
            .map((x) => {
                const userRights = currentUser?.userRights;
                const hasParentViewRight = userRights?.some(userRight =>
                    userRight.isActive
                    && userRight.right.id === x.id
                    && userRight.rightGrantLevel === RIGHT_GRANTS.View) ?? false;

                return {
                    hasParentViewRight,
                    section: x,
                    children: lodash.chain(rightData)
                        .filter(y => y.isOnNavDrawer && y.isActive && y.parentId === x.id)
                        .filter(y => !!y.pageOrder)
                        .sortBy(y => y.pageOrder)
                        .map((child) => {
                            const hasChildViewRight = userRights?.some(userRight =>
                                userRight.isActive
                                && userRight.right.id === x.id
                                && userRight.rightGrantLevel === RIGHT_GRANTS.View) ?? false;

                            return {
                                hasChildViewRight,
                                child,
                            };
                        })
                        .orderBy(x => x.hasChildViewRight, 'desc')
                        .value(),
                };
            })
            .orderBy(x => x.hasParentViewRight, 'desc')
            .map((x) => {
                return x.section.isPage
                    ? renderListItem(x.section, true, path)
                    : renderListSection(x.section, x.children.map(x => x.child), currentUserRights, path);
            }).value();
    }, [currentUser, rightData, path, isLoading]);

    const userSettings = useMemo<React.ReactElement>(() => {
        if (!isUserSettingsDialogOpen) return <div/>;
        return <UserSettings 
            isOpen={isUserSettingsDialogOpen}
            currentUser={currentUser}
            setIsUserDialogOpenCallback={(state : boolean) : void => setIsUserSettingsDialogOpen(state)}
            onClose={closeUserSettings}
            signOut={signOut}
        />;
    }, [isUserSettingsDialogOpen, currentUser]);

    /*================================================================================================================
     *                                                  Render Methods
     * ==============================================================================================================*/

    return ( 
        <div className={'fdc hfill'}>
            <NavBar
                env={EnvHelper.getEnvName()}
                version={VERSION.version}
                currentUser={currentUser}
                signOut={signOut}
                breadcrumbs={breadcrumbs}
                openDrawer={openDrawer}
                closeDrawer={closeDrawer}
                openUserSettings={openUserSettings}
                closeUserSettings={closeUserSettings}
                isLatestVersion={true}
                headings={[divisionHeading, departmentHeading]}
                disabled={isMasterDataSyncRunning}
                disabledTooltip={'Master Data is being synced.'}
                userSettingsDialog={userSettings}
            />
            <div className={'fdr flx1 oyh'}>
                <NavDrawer
                    env={EnvHelper.getEnvName()} 
                    path={location.pathname}
                    currentUser={currentUser}
                    isOpen={isNavDrawerOpen}
                    isLoading={isMasterDataSyncRunning}
                    sections={sections}
                    logOut={signOut}
                    closeDrawer={closeDrawer}
                    onHelpClick={openSupportTicketDialog}
                />
                { 
                    isMasterDataSyncRunning 
                        ? <Loader /> 
                        : <Routes />
                }              
            </div>
        </div>
    );

};

export default NavFrame;
