import axios, { AxiosPromise, Canceler, CancelTokenStatic } from 'axios';
import { INews } from '../../../@types/model/masterData/news/news';
import { INewsUpsert } from '../../../@types/model/masterData/news/newsUpsert';

export default class NewsHttpService {
    private static readonly CancelToken : CancelTokenStatic = axios.CancelToken;
    /**
     * Allows you to cancel list request
     */
    public static cancelGetList ?: Canceler;

    public static masterDataNewsGetList() : AxiosPromise<Array<INews>> {
        if (this.cancelGetList) {
            this.cancelGetList('GET Canceled');
            this.cancelGetList = undefined;
        }

        return axios.get(`${API_URL}/v1/MasterData/News/GetList`, {
            cancelToken: new this.CancelToken((c) => {
                this.cancelGetList = c;
            }),
        });
    }

    public static newsUpsert = (upsert : INewsUpsert, type : 'Add' | 'Edit') : AxiosPromise<INews> => {
        return axios.post(`${API_URL}/v1/MasterData/News/${type}`, upsert);
    }

    public static newsDelete = (newsId : number) : AxiosPromise<INews> => {
        return axios.delete(`${API_URL}/v1/MasterData/News/Delete`, {
            params: { newsId },
        });
    }
}

