import axios, { AxiosPromise, Canceler, CancelTokenStatic } from 'axios';
import { IPublicHoliday } from '../../../@types/model/masterData/publicHoliday/publicHoliday';
import { IPublicHolidayUpsert } from '../../../@types/model/masterData/publicHoliday/publicHolidayUpsert';

export default class PublicHolidayHttpService {
    private static readonly CancelToken : CancelTokenStatic = axios.CancelToken;
    /**
     * Allows you to cancel list request
     */
    public static cancelGetList ?: Canceler;

    public static masterDataPublicHolidayGetList() : AxiosPromise<Array<IPublicHoliday>> {
        if (this.cancelGetList) {
            this.cancelGetList('GET Canceled');
            this.cancelGetList = undefined;
        }

        return axios.get(`${API_URL}/v1/MasterData/PublicHoliday/GetList`, {
            cancelToken: new this.CancelToken((c) => {
                this.cancelGetList = c;
            }),
        });
    }

    public static publicHolidayUpsert = (publicHolidayUpsert : IPublicHolidayUpsert, type : 'Add' | 'Edit') : AxiosPromise<Array<IPublicHoliday>> => {
        return axios.post(`${API_URL}/v1/MasterData/PublicHoliday/${type}`, publicHolidayUpsert);
    }

    public static publicHolidayDelete = (id : number) : AxiosPromise<IPublicHoliday> => {
        return axios.delete(`${API_URL}/v1/MasterData/PublicHoliday/Delete`, {
            params: { id },
        });
    }
}