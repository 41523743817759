import { ThemeOptions, createTheme } from '@mui/material/styles';
import defaultTheme from './theme';

declare module '@mui/material/styles' {
    interface CustomTheme {
        custom : {
            table : {
                header ?: {
                    light ?: {
                        text ?: string;
                        background ?: string;
                    };
                    main ?: {
                        text ?: string;
                        background ?: string;
                    };
                };
                background ?: string;
                text ?: string;
                icon ?: string;
                row1 : {
                    default ?: string;
                    inactive ?: string;
                    warning ?: string;
                };
                row2 : {
                    default ?: string;
                    inactive ?: string;
                    warning ?: string;
                };
                footer : {
                    background ?: string;
                };
            };
            stepper : {
                activeIcon ?: string;
                activeIconText ?: string;
                completedIcon ?: string;
                completedLine ?: string;
            };
        };
    }

    interface Theme extends CustomTheme {}
    interface ThemeOptions extends CustomTheme {}
}

const theme : ThemeOptions = {
    palette: defaultTheme.colors.material,
    typography: {
        fontFamily: [
            'Montserrat',
        ].join(','),
    },
    custom: {
        table: {
            background: defaultTheme.colors.table.background,
            text: defaultTheme.colors.table.text,
            icon: defaultTheme.colors.table.icon,
            header: {
                light: {
                    text: defaultTheme.colors.table.header.light.text,
                    background: defaultTheme.colors.table.header.light.background,
                },
                main: {
                    text: defaultTheme.colors.table.header.main.text,
                    background: defaultTheme.colors.table.header.main.background,
                },
            },
            row1: {
                default: defaultTheme.colors.table.row1.default,
                inactive: defaultTheme.colors.table.row1.inactive,
                warning: defaultTheme.colors.table.row1.warning,
            },
            row2: {
                default: defaultTheme.colors.table.row2.default,
                inactive: defaultTheme.colors.table.row2.inactive,
                warning: defaultTheme.colors.table.row2.warning,
            },
            footer: {
                background: defaultTheme.colors.table.footer.background,
            },
        },
        stepper: {
            activeIcon: defaultTheme.colors.material.secondary.main,
            activeIconText: defaultTheme.colors.material.primary.dark,
            completedIcon: defaultTheme.colors.material.secondary.main,
            completedLine: defaultTheme.colors.material.secondary.main,
        },
    }
};

const materialTheme = createTheme(theme);

export default materialTheme;
