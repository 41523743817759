import axios, { AxiosPromise, Canceler, CancelTokenStatic } from 'axios';
import { IDivisionDayOfWeek } from '../../../@types/model/masterData/divisionDayOfWeek/divisionDayOfWeek';
import { IDivisionDayOfWeekUpsert } from '../../../@types/model/masterData/divisionDayOfWeek/divisionDayOfWeekUpsert';

export default class DivisionDayOfWeekHttpService {
    private static readonly CancelToken : CancelTokenStatic = axios.CancelToken;
    /**
     * Allows you to cancel list request
     */
    public static cancelGetList ?: Canceler;

    public static masterDataDivisionDayOfWeekGetList() : AxiosPromise<Array<IDivisionDayOfWeek>> {
        if (this.cancelGetList) {
            this.cancelGetList('GET Canceled');
            this.cancelGetList = undefined;
        }

        return axios.get(`${API_URL}/v1/MasterData/DivisionDayOfWeek/GetList`, {
            cancelToken: new this.CancelToken((c) => {
                this.cancelGetList = c;
            }),
        });
    }

    public static divisionDayOfWeekUpsert = (divisionDayOfWeekUpsert : IDivisionDayOfWeekUpsert, type : 'Add' | 'Edit') : AxiosPromise<IDivisionDayOfWeek> => {
        return axios.post(`${API_URL}/v1/MasterData/DivisionDayOfWeek/${type}`, divisionDayOfWeekUpsert);
    }

    public static divisionDayOfWeekDelete = (id : number) : AxiosPromise<IDivisionDayOfWeek> => {
        return axios.delete(`${API_URL}/v1/MasterData/DivisionDayOfWeek/Delete`, {
            params: { id },
        });
    }
}