import axios, { AxiosPromise, Canceler, CancelTokenStatic } from 'axios';
import { IClockingMethod } from '../../../@types/model/masterData/clockingMethod/clockingMethod';
import { IClockingMethodUpsert } from '../../../@types/model/masterData/clockingMethod/clockingMethodUpsert';

export default class ClockingMethodHttpService {
    private static readonly CancelToken : CancelTokenStatic = axios.CancelToken;
    /**
     * Allows you to cancel list request
     */
    public static cancelGetList ?: Canceler;

    public static masterDataClockingMethodGetList() : AxiosPromise<Array<IClockingMethod>> {
        if (this.cancelGetList) {
            this.cancelGetList('GET Canceled');
            this.cancelGetList = undefined;
        }

        return axios.get(`${API_URL}/v1/MasterData/ClockingMethod/GetList`, {
            cancelToken: new this.CancelToken((c) => {
                this.cancelGetList = c;
            }),
        });
    }

    public static clockingMethodUpsert = (clockingMethodUpsert : IClockingMethodUpsert, type : 'Add' | 'Edit') : AxiosPromise<IClockingMethod> => {
        return axios.post(`${API_URL}/v1/MasterData/ClockingMethod/${type}`, clockingMethodUpsert);
    }

    public static clockingMethodDelete = (id : number) : AxiosPromise<IClockingMethod> => {
        return axios.delete(`${API_URL}/v1/MasterData/ClockingMethod/Delete`, {
            params: { id },
        });
    }
}