import { openDb, UpgradeDB } from 'idb';
import { IUserToken } from '../@types/model/auth/userToken/userToken';
import { masterDataIndexedDBStores } from './masterDataSyncService';
import { processWizardIndexedDBStores} from './wizardService';
import { IPaymentTransaction } from '../@types/model/paymentTransactions/paymentTransaction';
import { IOptionType } from '@zz2/zz2-ui';

const SESSION_NAME = 'zz2-e-wages-session';
const SESSION_KEY = 'zz2-e-wages-session-token';

const SELECTED_USER_DIVISIONS_KEY = 'ewages-selected-user-divisions';
const SELECTED_USER_DEPARTMENTS_KEY = 'ewages-selected-user-departments';

const MASTER_DATA_LAST_SYNC_DATE = 'ewages-master-data-last-sync-date';

const SELECTED_DATE_FORMAT = 'ewages-selected-user-date-format';

const SELECTED_PAYMENT_TRANSACTION = 'ewages-selected-payment-transaction';

// eslint-disable-next-line no-unused-vars
let sessionCallback : (userToken : IUserToken | null) => void;

export async function getLocalStorageSession() : Promise<IUserToken | null> {
    let session : IUserToken | null = null;
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        session = await getSessionIndexedDB();
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    } else if (self.localStorage) {
        session = getSessionLocalStorage();
    }

    if (session) return session;

    return null;
}

export async function setLocalStorageSession(userToken : IUserToken | null) : Promise<void> {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        await setSessionIndexedDB(userToken);
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    } else if (self.localStorage) {
        setSessionLocalStorage(userToken);
    }

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (sessionCallback) {
        sessionCallback(userToken);
    }
}

function setSessionLocalStorage(userToken : IUserToken | null) : void {
    if (userToken) {
        localStorage.setItem(SESSION_KEY, JSON.stringify(userToken));
    } else {
        localStorage.removeItem(SESSION_KEY);
    }
}

function getSessionLocalStorage() : IUserToken | null {
    const session = localStorage.getItem(SESSION_KEY);

    if (session) return JSON.parse(session);

    return null;
}

/**
 * Creates all object stores up to the current DB version. i.e. for version 2, this function will execute for versions
 * 0, 1 and 2.
 * @param db
 */
export function upgradeDb(db : UpgradeDB) : void {
    // Session Store
    if (!db.objectStoreNames.contains(SESSION_NAME)) {
        db.createObjectStore<IUserToken, string>(SESSION_NAME);
    }

    // Master Data Stores
    masterDataIndexedDBStores(db);

    // Process Wizard Stores
    processWizardIndexedDBStores(db);
}

/**
 * Sets the auth session. If no session is specified, deletes the existing entry.
 * @param userToken The session.
 */
async function setSessionIndexedDB(userToken : IUserToken | null) : Promise<void> {
    const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

    const tx = db.transaction(SESSION_NAME, 'readwrite');

    const store = tx.objectStore(SESSION_NAME);

    await store.delete(SESSION_KEY);
    if (userToken) {
        await store.add(userToken, SESSION_KEY);
    }
    await tx.complete;
}

/**
 * Opens the DB and retrieves the current auth session.
 */
async function getSessionIndexedDB() : Promise<IUserToken> {
    const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

    const tx = db.transaction(SESSION_NAME, 'readonly');

    const result = tx.objectStore<IUserToken>(SESSION_NAME).get(SESSION_KEY);

    await tx.complete;

    return result;
}

/**
 * Specifies the callback that will be fired whenever the auth session undergoes a change.
 * @param callback
 */
// eslint-disable-next-line no-unused-vars
export async function onSessionChanged(callback : (userToken : IUserToken | null) => void) : Promise<void> {
    sessionCallback = callback;
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        indexedDBSessionChange();
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    } else if (self.localStorage) {
        const session = getSessionLocalStorage();
        sessionCallback(session);
    }
}

/**
 * Retrieves auth session, and once done fires the session callback.
 */
function indexedDBSessionChange() : void {
    getSessionIndexedDB().then((res) => {
        sessionCallback(res);
    }, () => {
        sessionCallback(null);
    });
}

///////////////////

/**
 * Stores User Selected Division Ids
 */
export function setUserSelectedDivisionsLocalStorage(selectedDivisionOptions ?: Array<IOptionType>) : void {
    if (selectedDivisionOptions) {
        localStorage.setItem(SELECTED_USER_DIVISIONS_KEY, JSON.stringify(selectedDivisionOptions));
    } else {
        localStorage.removeItem(SELECTED_USER_DIVISIONS_KEY);
    }
}

/**
 * Retrieves User Selected Division Ids
 */
export function getUserSelectedDivisionsLocalStorage() : Array<IOptionType> {
    const selectedDivisionOptions = localStorage.getItem(SELECTED_USER_DIVISIONS_KEY);

    if (selectedDivisionOptions) return JSON.parse(selectedDivisionOptions);

    return [];
}

/**
 * Stores User Selected Department Ids
 */
export function setUserSelectedDepartmentsLocalStorage(selectedDepartmentOptions ?: Array<IOptionType>) : void {
    if (selectedDepartmentOptions) {
        localStorage.setItem(SELECTED_USER_DEPARTMENTS_KEY, JSON.stringify(selectedDepartmentOptions));
    } else {
        localStorage.removeItem(SELECTED_USER_DEPARTMENTS_KEY);
    }
}

/**
 * Retrieves User Selected Department Ids
 */
export function getUserSelectedDepartmentsLocalStorage() : Array<IOptionType> {
    const selectedDepartmentOptions = localStorage.getItem(SELECTED_USER_DEPARTMENTS_KEY);

    if (selectedDepartmentOptions) return JSON.parse(selectedDepartmentOptions);

    return [];
}

/**
 * Stores master data last sync date
 */
export function setMasterDataLastSyncDateLocalStorage(lastSyncDate ?: moment.Moment) : void {
    if (lastSyncDate) {
        localStorage.setItem(MASTER_DATA_LAST_SYNC_DATE, JSON.stringify(lastSyncDate));
    } else {
        localStorage.removeItem(MASTER_DATA_LAST_SYNC_DATE);
    }
}

/**
 * Retrieves master data last sync date
 */
export function getMasterDataLastSyncDateLocalStorage() : moment.Moment | null {
    const lastSyncDate = localStorage.getItem(MASTER_DATA_LAST_SYNC_DATE);

    if (lastSyncDate) return JSON.parse(lastSyncDate);

    return null;
}

/**
 * Stores master data last sync date
 */
export function setUserSelectedDateFormatLocalStorage(selectedFormat : string | null) : void {
    if (selectedFormat) {
        localStorage.setItem(SELECTED_DATE_FORMAT, JSON.stringify(selectedFormat));
    } else {
        localStorage.removeItem(SELECTED_DATE_FORMAT);
    }
}

/**
 * Retrieves master data last sync date
 */
export function getUserSelectedDateFormatLocalStorage() : string | null {
    const selectedFormat = localStorage.getItem(SELECTED_DATE_FORMAT);

    if (selectedFormat) return JSON.parse(selectedFormat);

    return null;
}

/**
 * Stores master data last sync date
 */
export function setSelectedPaymentTransactionLocalStorage(selectedPaymentTransaction : IPaymentTransaction | null) : void {
    if (selectedPaymentTransaction) {
        localStorage.setItem(SELECTED_PAYMENT_TRANSACTION, JSON.stringify(selectedPaymentTransaction));
    } else {
        localStorage.removeItem(SELECTED_PAYMENT_TRANSACTION);
    }
}

/**
 * Retrieves master data last sync date
 */
export function getSelectedPaymentTransactionLocalStorage() : IPaymentTransaction | null {
    const selectedPaymentTransaction = localStorage.getItem(SELECTED_PAYMENT_TRANSACTION);

    if (selectedPaymentTransaction) return JSON.parse(selectedPaymentTransaction);

    return null;
}
