import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkApi } from '../../@types/redux';
import generalActions from './actions';
import axios, { AxiosError } from 'axios';

export default class GeneralThunk {
    /**
     * Adds an error snackbar to the queue. Attempts to retrieve the error message from the API, falling back to the default
     * message if the API returns no such thing.
     * @param defaultMessage
     * @param e
     */
    public static showErrorSnackbar = createAsyncThunk<
        void,
        {
            defaultMessage : string;
            e ?: any;
        },
        ThunkApi>(
            'SHOW_GENERAL_ERROR_SNACKBAR',
            async (params, thunkApi) => {
                let errorMessage = params.defaultMessage;

                if (params.e == null)
                {
                    errorMessage = 'Connection error.';
                } else if (typeof(params.e) === 'string') {
                    errorMessage = params.e;
                } else if (typeof(params.e.Message) === 'string') {
                    errorMessage = params.e.Message;
                } else if (typeof(params.e.data?.Message) === 'string') {
                    errorMessage = params.e.data.Message;
                } else if (typeof(params.e.response?.data?.Message) === 'string') {
                    errorMessage = params.e.response.data.Message;
                }

                thunkApi.dispatch(generalActions.enqueueSnackbar({
                    message: errorMessage,
                    options: {
                        variant: 'error',
                    },
                }));
            },
        );

    /**
     * Adds a success snackbar to the queue.
     * @param message
     */
    public static showSuccessSnackbar = createAsyncThunk<
        void,
        string,
        ThunkApi>(
            'SHOW_SUCCESS_SNACKBAR',
            async (message, thunkApi) => {
                thunkApi.dispatch(generalActions.enqueueSnackbar({
                    message,
                    options: {
                        variant: 'success',
                    },
                }));
            },
        );

    /**
     * Adds a warning snackbar to the queue.
     * @param message
     */
    public static showWarningSnackbar = createAsyncThunk<
        void,
        string,
        ThunkApi>(
            'SHOW_WARNING_SNACKBAR',
            async (message, thunkApi) => {
                thunkApi.dispatch(generalActions.enqueueSnackbar({
                    message,
                    options: {
                        variant: 'warning',
                    },
                }));
            },
        );

    /**
     * Adds an info snackbar to the queue.
     * @param message
     */
    public static showInfoSnackbar = createAsyncThunk<
        void,
        string,
        ThunkApi>(
            'SHOW_INFO_SNACKBAR',
            async (message, thunkApi) => {
                thunkApi.dispatch(generalActions.enqueueSnackbar({
                    message,
                    options: {
                        variant: 'info',
                    },
                }));
            },
        );
}
