import { createAction } from '@reduxjs/toolkit';
import { IRight } from '../../@types/model/auth/right/right';
import { IUser } from '../../@types/model/auth/user/user';
import { withPayloadType } from '../../service/helper/reduxHelper';
import { IRole } from '../../@types/model/auth/role/role';

export default class RightActions {
    public static setUserSettingsRefresh = createAction('RIGHT_SET_USER_SETTINGS_REFRESH', withPayloadType<boolean>());

    public static setIsLoading = createAction('RIGHT_SET_LOADING', withPayloadType<boolean>());
    public static setRightData = createAction('RIGHT_SET_LIST', withPayloadType<Array<IRight>>());
    public static setRoleData = createAction('ROLE_SET_LIST', withPayloadType<Array<IRole>>());
    public static setUserData = createAction('USER_SET_LIST', withPayloadType<Array<IUser>>());
    public static reset = createAction('RIGHT_RESET');
}
