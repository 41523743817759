import axios, { AxiosPromise, Canceler, CancelTokenStatic } from 'axios';
import { IJobType } from '../../../@types/model/masterData/jobType/jobType';
import { IJobTypeUpsert } from '../../../@types/model/masterData/jobType/jobTypeUpsert';

export default class JobTypeHttpService {
    private static readonly CancelToken : CancelTokenStatic = axios.CancelToken;
    /**
     * Allows you to cancel list request
     */
    public static cancelGetList ?: Canceler;

    public static masterDataJobTypeGetList() : AxiosPromise<Array<IJobType>> {
        if (this.cancelGetList) {
            this.cancelGetList('GET Canceled');
            this.cancelGetList = undefined;
        }

        return axios.get(`${API_URL}/v1/MasterData/JobType/GetList`, {
            cancelToken: new this.CancelToken((c) => {
                this.cancelGetList = c;
            }),
        });
    }

    public static jobTypeUpsert = (jobTypeUpsert : IJobTypeUpsert, type : 'Add' | 'Edit') : AxiosPromise<IJobType> => {
        return axios.post(`${API_URL}/v1/MasterData/JobType/${type}`, jobTypeUpsert);
    }

    public static jobTypeDelete = (id : number) : AxiosPromise<IJobType> => {
        return axios.delete(`${API_URL}/v1/MasterData/JobType/Delete`, {
            params: { id },
        });
    }
}