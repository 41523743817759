import { createReducer } from '@reduxjs/toolkit';
import { IRawClockTransaction } from '../../@types/model/rawClock/rawClockTransaction/rawClockTransaction';
import RawClockActions from './actions';


export interface IRawClockState {
    isLoading : boolean;
    rawClockTransactionList : Array<IRawClockTransaction> | null;
}

const initialState = {
    isLoading: false,
    rawClockTransactionList: null,
};

const rawClockReducer = createReducer<IRawClockState>(initialState, (builder) => {
    builder.addCase(RawClockActions.setIsLoading, (state, action) => {
        state.isLoading = action.payload;
    });

    builder.addCase(RawClockActions.setRawClockTransactionData, (state, action) => {
        state.rawClockTransactionList = action.payload;
    });
});

export default rawClockReducer;