import axios, { AxiosPromise, Canceler, CancelTokenStatic } from 'axios';
import { ICrop } from '../../../@types/model/masterData/crop/crop';
import { ICropUpsert } from '../../../@types/model/masterData/crop/cropUpsert';

export default class CropHttpService {
    private static readonly CancelToken : CancelTokenStatic = axios.CancelToken;
    /**
     * Allows you to cancel list request
     */
    public static cancelGetList ?: Canceler;

    public static masterDataCropGetList(departmentIds : Array<number>) : AxiosPromise<Array<ICrop>> {
        if (this.cancelGetList) {
            this.cancelGetList('GET Canceled');
            this.cancelGetList = undefined;
        }

        return axios.get(`${API_URL}/v1/MasterData/Crop/GetList`, {
            params: {
                departmentIds,
            },
            paramsSerializer: {
                indexes: null
            },
            cancelToken: new this.CancelToken((c) => {
                this.cancelGetList = c;
            }),
        });
    }

    public static cropUpsert = (cropUpsert : ICropUpsert, type : 'Add' | 'Edit') : AxiosPromise<ICrop> => {
        return axios.post(`${API_URL}/v1/MasterData/Crop/${type}`, cropUpsert);
    }

    public static cropDelete = (cropId : number) : AxiosPromise<ICrop> => {
        return axios.delete(`${API_URL}/v1/MasterData/Crop/Delete`, {
            params: { cropId },
        });
    }

}
